import { v4 as uuidv4 } from 'uuid';

import efgLogo from '../assets/EFG.png';
import GSLogo from '../assets/GS.png';
import BarclaysLogo from '../assets/barclays.png';
import BBVALogo from '../assets/bbva.png';
import BNPLogo from '../assets/bnp.png';
import BOALogo from '../assets/boa.png';
import CIBCLogo from '../assets/cibc.png';
import citiLogo from '../assets/citi.png';
import leonteqLogo from '../assets/leonteq.png';
import MarexLogo from '../assets/marexLogo2.png';
import MorganStanleyLogo from '../assets/morganStanleyLogo.png';
import NomuraLogo from '../assets/nomura.png';
import raiffeisenLogo from '../assets/raiffeisen.png';
import RBCLogo from '../assets/rbc.png';
import SocieteGeneraleLogo from '../assets/sgLogo.png';
import UBSLogo from '../assets/ubs.png';

import type { QuoteStatusEnum } from './enums/pricing';

export type Issuer = {
  key          : string,
  logo         : string,
  name         : string,
  quoteId      : string | null,
  displayPrice : string | null,
  price        : number | null,
  quoteStatus  : QuoteStatusEnum | null,
  error        : string | null,
  disabled     : boolean,
};

export const issuersList : Issuer[] = [
  {
    key          : uuidv4(),
    logo         : NomuraLogo,
    name         : 'Nomura',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : MarexLogo,
    name         : 'Marex',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : MorganStanleyLogo,
    name         : 'Morgan Stanley',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : SocieteGeneraleLogo,
    name         : 'Societe General',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : BNPLogo,
    name         : 'BNP Paribas',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : BarclaysLogo,
    name         : 'Barclays',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : true,
  },
  {
    key          : uuidv4(),
    logo         : CIBCLogo,
    name         : 'CIBC',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : citiLogo,
    name         : 'Citi',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : BBVALogo,
    name         : 'BBVA',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : true,
  },
  {
    key          : uuidv4(),
    logo         : BOALogo,
    name         : 'Bank Of America',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : true,
  },
  {
    key          : uuidv4(),
    logo         : UBSLogo,
    name         : 'UBS',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : GSLogo,
    name         : 'Goldman Sachs',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : leonteqLogo,
    name         : 'Leonteq',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : raiffeisenLogo,
    name         : 'Raiffeisen',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : efgLogo,
    name         : 'EFG',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
  {
    key          : uuidv4(),
    logo         : RBCLogo,
    name         : 'Royal Bank of Canada',
    quoteId      : null,
    displayPrice : null,
    price        : null,
    quoteStatus  : null,
    error        : null,
    disabled     : false,
  },
];

export const defaultIssuerSort = (l : Issuer, r : Issuer) : number => {
  if (l.disabled && !r.disabled) {
    return 1;
  }
  if (!l.disabled && r.disabled) {
    return -1;
  }
  return l.name.localeCompare(r.name);
};

export type IssuingEntity = {
  id                : number,
  shortName         : string,
  fullName          : string,
  countryRefId      : number,
  usableAutoPricing : boolean,
  logoPath          : string | null,
  ratingSAndP       : string | null,
  ratingMoody       : string | null,
  ratingFitch       : string | null,
  pipedriveId       : number | null,
};
