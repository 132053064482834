import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import type { GetProps,
  Input, TableColumnsType } from 'antd';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { CustomSelect } from '../../../Shared/CustomSelect/CustomSelect';
import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { ProductListPageViewEnum } from '../../../data/product/productEnum';
import { fetchProductsList } from '../../../store/productsStore/productApi';
import { setProductListPageViewState } from '../../../store/productsStore/productSlice';

import { generateGroupedListColumns, productsColumns, tradesListcolumns } from './ProductColumns';
import {
  groupByAttribute, groupByEventDate, groupByValueRange
} from './utils';

import type { ProductGroupedByAttribute } from './utils';
import type { CustomTableProps } from '../../../Shared/CustomTable/CustomTable';
import type { ProductListItem } from '../../../data/ProductType';
import type { TradeDataType } from '../../../data/TradesTypes';
import type { ProductListPageViewType } from '../../../store/productsStore/productsStoreTypes';
import type { AppDispatch, RootStateType } from '../../../store/store';

const ITEMS_PER_PAGE = 30;
const LIMIT = 60;

dayjs.extend(isBetween);

type ProductColumnType = TableColumnsType<ProductListItem> | TableColumnsType<ProductGroupedByAttribute>;
type ProductListType = ProductListItem[] | ProductGroupedByAttribute[];
type SearchProps = GetProps<typeof Input.Search>;
type TableDataProps = {
  products : ProductListType,
  columns  : ProductColumnType,
};

export const ProductsList = () : React.ReactNode => {
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  const productItems = useSelector((state : RootStateType) => state.productsReducer.products);
  const productViewState = useSelector((state : RootStateType) => state.productsReducer.productListPageView);
  const navigate = useNavigate();
  const {
    page, isLoading, totalCount, list: productsList,
  } = productItems;

  const [tableData, setTableData] = useState<TableDataProps>({
    products : [],
    columns  : productsColumns([]),
  });

  const getProducts = (pageNumber : number, status ?: 'Alive' | 'Other', orderBy ?: string) : void => {
    void dispatch(fetchProductsList({
      page  : pageNumber,
      limit : LIMIT,
      status,
      orderBy,
    }));
  };

  useEffect(() => {
    if (!productsList.length) {
      getProducts(page);
    }
  }, []);

  const setViewState = (viewState : Partial<ProductListPageViewType>) : void => {
    dispatch(setProductListPageViewState({
      ...productViewState,
      ...viewState,
    }));
  };

  /**
   * Groups a list of products by client name or representative extrated from the trades
   */
  const groupProductListByTradeParam = (list : ProductListItem[], attribute : keyof TradeDataType) : ProductGroupedByAttribute[] => {
    const result : ProductGroupedByAttribute[] = [];
    list.forEach((product) => {
      product.trades.forEach((trade) => {
        let found = result.find((item) => item.groupName === trade[attribute]);
        if (!found) {
          found = {
            id        : trade[attribute] as string,
            groupName : trade[attribute] as string,
            products  : [],
          };
          result.push(found);
        }
        const filteredProduct = {
          ...product,
          trades : product.trades.filter((t) => t[attribute] === trade[attribute]),
        };

        // Check if the product with the same ISIN already exists in the group
        if (!found.products.find((p) => p.isin === filteredProduct.isin)) {
          found.products.push(filteredProduct);
        }
      });
    });
    return result;
  };

  const expandedTradesRowRender = (trades : ProductListItem['trades']) : React.ReactNode => (
    trades.length
      ? (
        <Table
          key = {productViewState.view}
          columns = {tradesListcolumns}
          className = {'expanded__table'}
          dataSource = {trades}
          rowKey = {(record) : string => record.id.toString()}
          pagination = {false}
          id = {'expanded__table__inner__table--2'}
          rowClassName = {(_, index) : string => (index % 2 === 0 ? 'inner-table-row-even' : 'inner-table-row-odd')}
          onRow = {(record) => ({ onClick : () : void => navigate(`/trades/${record.id.toString()}`) })}
        />
      )
      : <span className = {'no-content'}>No trades</span>
  );

  const expandedProductsRowRender = (list : ProductListItem[]) : React.ReactNode => (
    list.length
      ? (
        <Table
          key = {productViewState.view}
          columns = {productsColumns(list)}
          className = {'expanded__table'}
          dataSource = {list}
          rowKey = {(record) : number => record.id}
          rowClassName = {(_, index) : string => (index % 2 === 0 ? 'table-row-even' : 'table-row-odd')}
          expandable = {{
            expandedRowRender    : (record) : React.ReactNode => expandedTradesRowRender(record.trades),
            onExpandedRowsChange : (expandedKeys) : void => {
              setViewState({ innerListExpandedRowKeys : [...expandedKeys]});
            },
            defaultExpandedRowKeys : productViewState.innerListExpandedRowKeys,
            expandIcon             : ({
              expanded, onExpand, record,
            }) : React.ReactNode => (
              <Button
                type = {'text'}
                icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                onClick = {(e) : void => {
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            ),
          }}
          pagination = {productsList.length <= 6
            ? false
            : {
              position        : ['bottomCenter'],
              defaultCurrent  : productViewState.innerListPageNumber,
              defaultPageSize : 6,
              onChange        : (pageNumber : number) : void => {
                setViewState({ innerListPageNumber : pageNumber });
              },
            }}
          onRow = {(record) => ({ onClick : () : void => navigate(`/products/${record.id.toString()}`) })}
        />
      )
      : <span className = {'no-content'}>No Products</span>
  );

  const changeDisplayView = (selectedView : ProductListPageViewEnum) : void => {
    let productsListToDisplay : ProductListType = [];
    let viewColumns : ProductColumnType = [];
    switch (selectedView) {
      case ProductListPageViewEnum.DEFAULT:
        productsListToDisplay = productsList;
        viewColumns = productsColumns(productsList);
        break;
      case ProductListPageViewEnum.BY_CLIENT:
        productsListToDisplay = groupProductListByTradeParam(productsList, 'clientName');
        viewColumns = generateGroupedListColumns('Client');
        break;
      case ProductListPageViewEnum.BY_REPRESENTATIVE:
        productsListToDisplay = groupProductListByTradeParam(productsList, 'representative');
        viewColumns = generateGroupedListColumns('Representative');
        break;
      case ProductListPageViewEnum.BY_PRODUCT_TYPE:
        productsListToDisplay = groupByAttribute(productsList, 'productType');
        viewColumns = generateGroupedListColumns('Product Type');
        break;
      case ProductListPageViewEnum.BY_ISSUER:
        productsListToDisplay = groupByAttribute(productsList, 'issuerShortName');
        viewColumns = generateGroupedListColumns('Issuer');
        break;
      case ProductListPageViewEnum.BY_VALORIZATION:
        productsListToDisplay = groupByValueRange(productsList, 'valorisation');
        viewColumns = generateGroupedListColumns('Valorisation Level');
        break;
      case ProductListPageViewEnum.BY_NEXT_EVENT:
        productsListToDisplay = groupByEventDate(productsList, 'nextEventDate');
        viewColumns = generateGroupedListColumns('Next Event Date');
        break;
      default:
        break;
    }

    setTableData({
      columns  : viewColumns,
      products : productsListToDisplay,
    });
  };

  useEffect(() => {
    if (!productsList.length) {
      return;
    }
    changeDisplayView(productViewState.view);
  }, [productsList]);

  const onSearch : SearchProps['onSearch'] = (searchText) : void => {
    const { view } = productViewState;
    let searchedProducts = productsList;
    let tradeParamGroupedProducts : ProductGroupedByAttribute[] = [];

    setSearchValue(searchText);

    const lowerSearchText = searchText.toLowerCase();
    if (searchText) {

      searchedProducts = productsList.filter(
        (product) => product.name.toLowerCase().includes(lowerSearchText)
          || product.isin.toLowerCase().includes(lowerSearchText)
          || product.productType.toLowerCase().includes(lowerSearchText)
          || product.issuerShortName.toLowerCase().includes(lowerSearchText)
          || product.trades.some((t) => t.clientName.toLowerCase().includes(lowerSearchText)
            || t.representative.toLowerCase().includes(lowerSearchText))
      );

      if (view === ProductListPageViewEnum.BY_CLIENT || view === ProductListPageViewEnum.BY_REPRESENTATIVE) {
        const attribute = view === ProductListPageViewEnum.BY_CLIENT ? 'clientName' : 'representative';
        tradeParamGroupedProducts = groupProductListByTradeParam(searchedProducts, attribute)
          .filter(
            (product) => product.groupName.toLowerCase().includes(lowerSearchText)
          );
      }
    }

    switch (view) {
      case ProductListPageViewEnum.DEFAULT:
        setTableData({
          ...tableData,
          products : searchedProducts,
        });
        break;
      case ProductListPageViewEnum.BY_CLIENT:
        setTableData({
          ...tableData,
          products : tradeParamGroupedProducts,
        });
        break;
      case ProductListPageViewEnum.BY_REPRESENTATIVE:
        setTableData({
          ...tableData,
          products : tradeParamGroupedProducts,
        });
        break;
      case ProductListPageViewEnum.BY_VALORIZATION:
        setTableData({
          ...tableData,
          products : groupByValueRange(searchedProducts, 'valorisation'),
        });
        break;
      case ProductListPageViewEnum.BY_NEXT_EVENT:
        setTableData({
          ...tableData,
          products : groupByEventDate(searchedProducts, 'nextEventDate'),
        });
        break;
      case ProductListPageViewEnum.BY_ISSUER:
        setTableData({
          ...tableData,
          products : groupByAttribute(searchedProducts, 'issuerShortName'),
        });
        break;
      case ProductListPageViewEnum.BY_PRODUCT_TYPE:
        setTableData({
          ...tableData,
          products : groupByAttribute(searchedProducts, 'productType'),
        });
        break;

      default:
        break;
    }
  };

  const tableProps : Pick<CustomTableProps<ProductListItem>, 'actions' | 'CustomComponent' | 'search'> = {
    actions : [
      {
        title   : 'Add Product',
        onClick : () : void => navigate('/products/new'),
        variant : 'primary',
      },
    ],
    CustomComponent : (<CustomSelect
      defaultValue = {ProductListPageViewEnum.BY_NEXT_EVENT as string}
      selectedValue = {productViewState.view}
      options = {Object.values(ProductListPageViewEnum)}
      label = {`Group by ${productViewState.view}`}
      onChange = {(val) : void => {
        changeDisplayView(val as ProductListPageViewEnum);
        setSearchValue('');
        setViewState({
          view                     : val as ProductListPageViewEnum,
          expandedRowKeys          : [],
          pageNumber               : 1,
          innerListExpandedRowKeys : [],
          innerListPageNumber      : 1,
        });
      }}
    />),
    search : {
      searchValue,
      onSearch,
    },
  };

  const {
    columns, products,
  } = tableData;

  return (
    <div className = {'products_list_container'}>
      {productViewState.view === ProductListPageViewEnum.DEFAULT
        ? (
          <CustomTable<ProductListItem>
            key = {productViewState.view}
            loading = {isLoading}
            columns = {columns as TableColumnsType<ProductListItem>}
            dataList = {products as ProductListItem[]}
            {...tableProps}
            rowKey = {(record) : number => record.id}
            colsTopApplyDateFilter = {['issueDate', 'maturityDate', 'nextEventDate']}
            colsTopApplySeachFilter = {['isin', 'name']}
            sticky = {true}
            expandable = {{
              expandedRowRender    : (record) : React.ReactNode => expandedTradesRowRender(record.trades),
              onExpandedRowsChange : (expandedKeys) : void => {
                setViewState({ expandedRowKeys : [...expandedKeys]});
              },
              defaultExpandedRowKeys : productViewState.expandedRowKeys,
              expandIcon             : ({
                expanded, onExpand, record,
              }) : React.ReactNode => (
                <Button
                  type = {'text'}
                  icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  onClick = {(e) : void => {
                    e.stopPropagation();
                    onExpand(record, e);
                  }}
                />
              ),
            }}
            pagination = {products.length <= ITEMS_PER_PAGE
              ? false
              : {
                position        : ['bottomCenter'],
                defaultPageSize : ITEMS_PER_PAGE,
                total           : totalCount,
                defaultCurrent  : productViewState.pageNumber,
                onChange        : (pageNumber : number) : void => {
                  setViewState({ pageNumber });
                },
              }}
            onRow = {(record) => ({ onClick : () : void => navigate(`/products/${record.id.toString()}`) })}
          />
        )
        : (
          <CustomTable<ProductGroupedByAttribute>
            key = {productViewState.view}
            loading = {isLoading}
            columns = {columns as TableColumnsType<ProductGroupedByAttribute>}
            dataList = {products as ProductGroupedByAttribute[]}
            {...tableProps}
            rowKey = {(record) : string => record.id.toString()}
            sticky = {true}
            expandable = {{
              expandedRowRender    : (record) : React.ReactNode => expandedProductsRowRender(record.products),
              onExpandedRowsChange : (expandedKeys) : void => {
                setViewState({ expandedRowKeys : [...expandedKeys]});
              },
              defaultExpandedRowKeys : productViewState.expandedRowKeys,
              expandIcon             : ({
                expanded, onExpand, record,
              }) : React.ReactNode => (
                <Button
                  type = {'text'}
                  icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  onClick = {(e) : void => {
                    onExpand(record, e);
                  }}
                />
              ),
              expandedRowClassName : () : string => 'expanded__table-row',
              expandRowByClick     : true,
            }}
            pagination = {products.length <= ITEMS_PER_PAGE
              ? false
              : {
                position        : ['bottomCenter'],
                defaultPageSize : ITEMS_PER_PAGE,
                defaultCurrent  : productViewState.pageNumber,
                onChange        : (pageNumber : number) : void => {
                  setViewState({ pageNumber });
                },
              }}
          />
        )}
    </div>
  );
};

