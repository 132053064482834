import type { FormInstance } from 'antd';

import { BarrierTypeEnum, ProductSubTypeEnum, ProductTypeEnum } from '../../../data/product/productEnum';

import type { ProductRedemption } from '../../../data/ProductType';

/**
 * Creates a single ProductRedemption object given the necessary parameters.
 * This function is a factory to create a ProductRedemption object with all
 * necessary fields.
 *
 * @param startValue - value at which the redemption starts
 * @param isStartIncluded - whether the start value is included in the redemption range
 * @param endValue - value at which the redemption ends, or null if it is open-ended
 * @param isEndIncluded - whether the end value is included in the redemption range
 * @param redemptionValueAtStart - value of the redemption at the start of the redemption range
 * @param valueAtEnd - value of the redemption at the end of the redemption range
 * @param isActivated - whether the redemption is activated
 * @param addOrReplace - how this redemption should interact with existing ones in the product
 * @returns a ProductRedemption object with all the given fields
 */
const createRedemptionEntry = (
  startValue : number,
  isStartIncluded : boolean,
  endValue : number | null,
  isEndIncluded : boolean,
  redemptionValueAtStart : number,
  redemptionValueAtEnd : number,
  isActivated : boolean,
  addOrReplace : 'Add' | 'Replace',
  barrierType ?: string
) : ProductRedemption => ({
  startValue,
  isStartIncluded,
  endValue,
  isEndIncluded,
  redemptionValueAtStart,
  redemptionValueAtEnd,
  isActivated,
  addOrReplace,
  barrierType,
});

export const createRedemptionsList = (
  form : FormInstance,
  attrs : {
    productSubType    : string,
    hasMaturityCap    : boolean,
    hasMaturityRebate : boolean,
    barrierType       : string,
    productType       : string,
    strike            : number,
  }
) : ProductRedemption[] => {

  const {
    productType, productSubType, hasMaturityCap, hasMaturityRebate: hasRebate, strike, barrierType,
  } = attrs;

  const barrierLevel = form.getFieldValue('barrierLevel') as number;
  const bonusLevel = form.getFieldValue('bonusLevel') as number;
  const bonusBarrier = form.getFieldValue('bonusBarrier') as number;
  const upsideLeverage = form.getFieldValue('upsideLeverage') as number;
  const upsideStrike = form.getFieldValue('upsideStrike') as number;
  const downsideLeverage = form.getFieldValue('downsideLeverage') as number;
  const cap = form.getFieldValue('maturityCap') as number;
  const rebate = form.getFieldValue('rebate') as number;
  const rebateBarrier = form.getFieldValue('rebateBarrier') as number;

  const redemptionsList : ProductRedemption[] = [];

  const productTypeAsEnum : ProductTypeEnum = ProductTypeEnum[productType as keyof typeof ProductTypeEnum];
  const productSubTypeAsEnum : ProductSubTypeEnum = ProductSubTypeEnum[productSubType as keyof typeof ProductSubTypeEnum];

  // valueAtProtectionBarrier: redemption value at barrier
  const valueAtProtectionBarrier = barrierLevel * downsideLeverage;

  switch (productTypeAsEnum) {
    case ProductTypeEnum.Autocall:
      // Phoenix, Classic and Reverse
      if (productSubTypeAsEnum === ProductSubTypeEnum.Phoenix
        || productSubTypeAsEnum === ProductSubTypeEnum.Classic
        || productSubTypeAsEnum === ProductSubTypeEnum.Reverse) {

        if (barrierType === BarrierTypeEnum.European.toString()) {
          return [
            createRedemptionEntry(0, true, barrierLevel, true, 0, valueAtProtectionBarrier, false, 'Add'),
            createRedemptionEntry(barrierLevel, false, null, false, 100, 100, false, 'Add'),
          ];
        }
        return [
          createRedemptionEntry(0, true, barrierLevel, false, 0, valueAtProtectionBarrier, false, 'Add'),
          createRedemptionEntry(barrierLevel, false, strike, false, 100, 100, false, 'Add', barrierType),
          createRedemptionEntry(barrierLevel, true, null, false, 100, 100, false, 'Add'),
        ];
      }

      // BoosterBonus
      if (productSubTypeAsEnum === ProductSubTypeEnum.BoosterBonus) {

        if (hasRebate) {
          return [
            createRedemptionEntry(0, true, rebateBarrier, true, 0, rebate, false, 'Replace'),
            createRedemptionEntry(rebateBarrier, true, null, false, 0, rebate, false, 'Add'),
          ];
        }

        // The  underlying value at bonus == upside leverage
        const underlyingValueAtUpsideLev = (bonusLevel - strike) / upsideLeverage * 100;

        // The Underlying value at Cap
        const underlyingValueAtCap = (cap - upsideStrike) / upsideLeverage * 100;

        redemptionsList.push(
          createRedemptionEntry(0, true, barrierLevel, true, 0, valueAtProtectionBarrier, false, 'Add')
        );

        if (barrierType === BarrierTypeEnum.UsClose.toString() || barrierType === BarrierTypeEnum.UsIntraday.toString()) {
          redemptionsList.push(
            createRedemptionEntry(barrierLevel, true, strike, false, valueAtProtectionBarrier, 100, false, 'Add', barrierType)
          );
        }

        redemptionsList.push(
          createRedemptionEntry(barrierLevel, false, bonusBarrier, true, 100, 100, false, 'Add'),
          createRedemptionEntry(bonusBarrier, false, underlyingValueAtUpsideLev, true, bonusLevel, bonusLevel, false, 'Add')
        );

        if (hasMaturityCap) {
          redemptionsList.push();

          redemptionsList.push(
            createRedemptionEntry(underlyingValueAtUpsideLev, false, underlyingValueAtCap, true, bonusLevel, cap, false, 'Add'),
            createRedemptionEntry(underlyingValueAtCap, false, null, true, cap, cap, false, 'Add')
          );
        } else {
          redemptionsList.push(
            createRedemptionEntry(underlyingValueAtUpsideLev, false, null, true, bonusLevel, upsideLeverage, false, 'Add')
          );
        }
      }
      break;
    case ProductTypeEnum.Digital: {
      throw new Error('Not implemented yet: ProductTypeEnum.Digital case');
    }
    case ProductTypeEnum.CreditLinkedNote: {
      throw new Error('Not implemented yet: ProductTypeEnum.CreditLinkedNote case');
    }
    case ProductTypeEnum.Participation: {
      throw new Error('Not implemented yet: ProductTypeEnum.Participation case');
    }
    case ProductTypeEnum.Rates: {
      throw new Error('Not implemented yet: ProductTypeEnum.Rates case');
    }
    case ProductTypeEnum.Custom: {
      throw new Error('Not implemented yet: ProductTypeEnum.Custom case');
    }
    default: {
      throw new Error('Unkown error');
    }
  }

  return redemptionsList;
};
