import { createAsyncThunk } from '@reduxjs/toolkit';

import { myFetch } from '../../config/api';

import type { GetProductPayloadType, GetProductsSuccessPayload } from './productsStoreTypes';
import type { IssuingEntity } from '../../data/Issuers';
import type { SingleProductType } from '../../data/ProductType';

export const fetchProductsList = createAsyncThunk<
  GetProductsSuccessPayload,
  GetProductPayloadType,
  { rejectValue : string }
>(
  'products/fetchProductsList',
  async ({
    page, limit, status, orderBy,
  } : GetProductPayloadType, { rejectWithValue }) => {
    try {
      let url = `/products?page=${page}&limit=${limit}`;
      if (status) {
        url = `${url}&status=${status}`;
      }
      if (orderBy) {
        url = `${url}&order_by=${orderBy}`;
      }

      const response = await myFetch<GetProductsSuccessPayload>('GET', url);

      return {
        products   : response.products,
        totalCount : response.totalCount,
      };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getSingleProduct = createAsyncThunk<
  { product : SingleProductType },
  { productId : string },
  { rejectValue : string }
>(
  'products/getSingleProduct',
  async ({ productId } : { productId : string }, { rejectWithValue }) => {
    try {
      const response = await myFetch<SingleProductType>('GET', `/products/${productId}`);

      response.events = response.events ?? [];
      response.fields = response.fields ?? [];

      return { product : response };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getIssuers = createAsyncThunk<
  { issuers : IssuingEntity[] },
  void,
  { rejectValue : string }
>(
  'products/getIssuers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await myFetch<IssuingEntity[]>('GET', '/issuers');
      return { issuers : response };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
