import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { productsSlice } from './productsStore/productSlice';

const rootReducer = combineReducers({
  productsReducer : productsSlice.reducer,
});

export const store = configureStore({
  reducer : rootReducer,
});

export type RootStateType = ReturnType<typeof store.getState>;

export type RootReducerType = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export default store;
