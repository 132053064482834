import React from 'react';

import {
  type TableColumnsType, Tag
} from 'antd';
import type { ColumnFilterItem } from 'antd/es/table/interface';

import dayjs from 'dayjs';

import { ClipboardCopy } from '../../../Shared/ClipboardCopy/ClipboardCopy';
import { ValorisationLevel } from '../../../data/enums/labels';
import {
  greenColor, redColor, whiteColor
} from '../../../styles/colors';
import { parseCurrency } from '../../../utils/currencyparser';
import { ProductValorisation } from '../Shared/ProductValuation/ProductValuation';

import {
  type ProductGroupedByAttribute,
  valorisation100, valorisation105, valorisation90
} from './utils';

import type { ProductListItem } from '../../../data/ProductType';
import type { TradeDataType } from '../../../data/TradesTypes';

export const productsColumns = (productList : ProductListItem[]) : TableColumnsType<ProductListItem> => [
  {
    title     : 'ISIN',
    key       : 'isin',
    dataIndex : 'isin',
    width     : 130,
    render    : (renderValue : string) : React.ReactNode => <ClipboardCopy text = {renderValue} />,
  },
  {
    title     : 'Name',
    dataIndex : 'name',
    width     : 300,
    sorter    : (left, right) : number => left.name.localeCompare(right.name),
    render    : (renderValue : string) : React.ReactNode => <ClipboardCopy text = {renderValue} />,
  },
  {
    title     : 'Product Type',
    dataIndex : 'productType',
    width     : 240,
    sorter    : (left, right) : number => left.productType.localeCompare(right.productType),
    filters   : Object.values(
      // eslint-disable-next-line @stylistic/block-spacing
      productList.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
        acc[current.productType] = acc[current.productType] ?? {
          text  : current.productType,
          // eslint-disable-next-line id-denylist
          value : current.productType,
        };
        return acc;
      }, {})
    ),
    onFilter     : (filterValue, record) : boolean => record.productType.startsWith(filterValue as string),
    filterSearch : true,
  },
  {
    title     : 'Issuer',
    dataIndex : 'issuerShortName',
    width     : 240,
    sorter    : (left, right) : number => left.issuerShortName.localeCompare(right.issuerShortName),
    filters   : Object.values(
      // eslint-disable-next-line @stylistic/block-spacing
      productList.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
        acc[current.issuerShortName] = acc[current.productType] ?? {
          text  : current.issuerShortName,
          // eslint-disable-next-line id-denylist
          value : current.issuerShortName,
        };
        return acc;
      }, {})
    ),
    onFilter     : (filterValue, record) : boolean => record.issuerShortName.startsWith(filterValue as string),
    filterSearch : true,
  },
  {
    title     : 'Issue Date',
    dataIndex : 'issueDate',
    align     : 'right',
    width     : 150,
    sorter    : (left, right) : number => new Date(left.issueDate).getTime() - new Date(right.issueDate).getTime(),
    render    : (renderValue : string) : React.ReactNode => dayjs(renderValue).format('DD/MM/YYYY'),
  },
  {
    title     : 'Maturity Date',
    dataIndex : 'maturityDate',
    align     : 'right',
    width     : 150,
    sorter    : (left, right) : number => new Date(left.maturityDate).getTime() - new Date(right.maturityDate).getTime(),
    render    : (renderValue : string) : React.ReactNode => dayjs(renderValue).format('DD/MM/YYYY'),
  },
  {
    title     : 'Valorisation (%)',
    dataIndex : 'valorisation',
    align     : 'center',
    width     : 190,
    sorter    : (left, right) : number => left.valorisation - right.valorisation,
    filters   : [
      {
        text  : '>= 105',
        value : ValorisationLevel.greenPos,
      },
      {
        text  : '100 <= val < 105',
        value : ValorisationLevel.greenNormal,
      },
      {
        text  : '90 <= val < 100',
        value : ValorisationLevel.orange,
      },
      {
        text  : 'val < 90',
        value : ValorisationLevel.red,
      },
    ],

    onFilter : (filterValue, record) : boolean => {
      switch (filterValue as ValorisationLevel) {
        case ValorisationLevel.greenPos:
          return record.valorisation >= valorisation105;
        case ValorisationLevel.greenNormal:
          return record.valorisation >= valorisation100 && record.valorisation < valorisation105;
        case ValorisationLevel.orange:
          return record.valorisation >= valorisation90 && record.valorisation < valorisation100;
        case ValorisationLevel.red:
          return record.valorisation < valorisation90;
        default:
          return true;
      }
    },
    render : (renderValue : number) : React.ReactNode => <ProductValorisation valorisationValue = {renderValue} />,
  },
  {
    title     : 'Outstanding',
    dataIndex : 'outstanding',

    sorter : (left : ProductListItem, right : ProductListItem) : number => {
      const aSum = left.trades.reduce((acc, obj) => acc + obj.outstandingAmount, 0);
      const bSum = right.trades.reduce((acc, obj) => acc + obj.outstandingAmount, 0);
      return aSum - bSum;
    },
    align : 'right',

    render : (_, record) : React.ReactNode => parseCurrency(record.trades.reduce((acc, obj) => acc + obj.outstandingAmount, 0), record.currency),
  },
  {
    title     : 'Next Event Date',
    dataIndex : 'nextEventDate',
    align     : 'right',
    width     : 170,
    sorter    : (left, right) : number => new Date(left.nextEventDate).getTime() - new Date(right.nextEventDate).getTime(),
    render    : (renderValue : string) : string => dayjs(renderValue).format('DD/MM/YYYY'),
  },
  {
    title     : 'Status',
    dataIndex : 'status',
    align     : 'center',
    width     : 90,
    render    : (_, record : ProductListItem) : React.ReactNode => (
      <Tag
        color = {record.status === 'Alive' ? 'green' : 'red'}
        style = {{
          background : record.status === 'Alive' ? greenColor : redColor,
          border     : 'none',
          fontSize   : '13px',
          color      : whiteColor,
        }}
      >{record.status}
      </Tag>
    ),
    filters : [
      {
        text  : 'Alive',
        value : 'Alive',
      },
      {
        text  : 'Expired',
        value : 'expired',
      },
    ],
    onFilter             : (filterValue, record) : boolean => record.status.includes(filterValue as string),
    defaultFilteredValue : ['Alive'],
  },
];

export const tradesListcolumns : TableColumnsType<TradeDataType> = [
  {
    title     : 'Trade Id',
    dataIndex : 'id',
    key       : 'id',
  },
  {
    title     : 'Representative',
    dataIndex : 'representative',
    key       : 'representative',
  },
  {
    title     : 'Client Name',
    dataIndex : 'clientName',
    key       : 'clientName',
  },
  {
    title     : 'Trade Date ',
    dataIndex : 'tradeDate',
    key       : 'tradeDate',
    render    : (date) : React.ReactNode => <>{dayjs(date as string).format('DD/MM/YYYY')}</>,
  },
  {
    title     : 'Outstanding Amount',
    dataIndex : 'outstandingAmount',
    key       : 'outstandingAmount',
    render    : (val, record) : React.ReactNode => <>{parseCurrency(val as number, record.currency)}</>,
  },
];

export const generateGroupedListColumns = (title : string) : TableColumnsType<ProductGroupedByAttribute> => [
  {
    title,
    dataIndex : 'groupName',
    render    : (val, record) : React.ReactNode => (
      <p>
        <span className = {'group-name'}>
          {val}

          {' '}

        </span>

        <span className = {'product-group-count'}>({record.products.length} products)</span>
      </p>

    ),
  },
];
