import { createSlice } from '@reduxjs/toolkit';

import { ProductListPageViewEnum } from '../../data/product/productEnum';

import { fetchProductsList, getIssuers, getSingleProduct } from './productApi';

import type { ProductListPageViewType } from './productsStoreTypes';
import type { IssuingEntity } from '../../data/Issuers';
import type { ProductListItem, SingleProductType } from '../../data/ProductType';
import type { PayloadAction } from '@reduxjs/toolkit';

type ProductsInitialStateType = {
  products : {
    list       : ProductListItem[],
    isLoading  : boolean,
    totalCount : number,
    page       : number,
    error      : string,
  },
  product : {
    item      : SingleProductType | null,
    isLoading : boolean,
    error     : string,
  },
  issuers : {
    list      : IssuingEntity[],
    isLoading : boolean,
    error     : string | null,
  },

  // To maintain the state of the product list page
  productListPageView : ProductListPageViewType,
};

const initialState : ProductsInitialStateType = {
  products : {
    list       : [],
    isLoading  : false,
    totalCount : 0,
    page       : 1,
    error      : '',
  },
  product : {
    item      : null,
    isLoading : false,
    error     : '',
  },
  issuers : {
    list      : [],
    isLoading : false,
    error     : null,
  },
  productListPageView : {
    pageNumber               : 1,
    view                     : ProductListPageViewEnum.DEFAULT,
    expandedRowKeys          : [],
    innerListPageNumber      : 1,
    innerListExpandedRowKeys : [],
  },
};

export const productsSlice = createSlice({
  name     : 'products',
  initialState,
  reducers : {
    setProductListPageViewState : (state, action : PayloadAction<ProductListPageViewType>) : void => {
      state.productListPageView.pageNumber = action.payload.pageNumber;
      state.productListPageView.view = action.payload.view;
      state.productListPageView.expandedRowKeys = action.payload.expandedRowKeys;
      state.productListPageView.innerListPageNumber = action.payload.innerListPageNumber;
      state.productListPageView.innerListExpandedRowKeys = action.payload.innerListExpandedRowKeys;
    },
  },
  extraReducers : (builder) : void => {
    builder.addCase(fetchProductsList.pending, (state) => {
      state.products.isLoading = true;
    });
    builder.addCase(fetchProductsList.fulfilled, (state, action) => {
      state.products.isLoading = false;
      state.products.list = action.payload.products;
      state.products.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchProductsList.rejected, (state, action) => {
      state.products.isLoading = false;
      state.products.error = action.error.message ?? '';
    });

    builder.addCase(getSingleProduct.pending, (state) => {
      state.product.isLoading = true;
      state.product.error = '';
    });
    builder.addCase(getSingleProduct.fulfilled, (state, action) => {
      state.product.isLoading = false;
      state.product.item = action.payload.product;
    });
    builder.addCase(getSingleProduct.rejected, (state, action) => {
      state.product.isLoading = false;
      state.product.error = action.error.message ?? '';
      state.product.item = null;
    });

    builder.addCase(getIssuers.pending, (state) => {
      state.issuers.isLoading = true;
      state.issuers.error = '';
    });
    builder.addCase(getIssuers.fulfilled, (state, action) => {
      state.issuers.isLoading = false;
      state.issuers.list = action.payload.issuers;
    });
    builder.addCase(getIssuers.rejected, (state, action) => {
      state.issuers.isLoading = false;
      state.issuers.error = action.error.message ?? '';
    });
  },
});

export const { setProductListPageViewState } = productsSlice.actions;

export default productsSlice.reducer;
