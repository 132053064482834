import React from 'react';

import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Button, Flex, Space } from 'antd';

import { EventTypesEnum } from '../../data/product/productEnum';
import { isNumberString } from '../../utils/stringUtils';
import { EditableText } from '../EditableText/EditableText';

import type { ProductEvent } from '../../data/ProductType';

const labelToClassName : Map<string, string> = new Map([
  [EventTypesEnum.Coupon, '--coupon'],
  [EventTypesEnum.MaturityDate, '--maturity'],
  [EventTypesEnum.AutocallAndCoupon, '--autocall'],
  [EventTypesEnum.FinalFixing, '--finalfixing'],
  [EventTypesEnum.IssueDate, '--issue'],
  [EventTypesEnum.InitialFixing, '--initialfixing'],
]);

type CalendarEventCardProps = {
  event               : ProductEvent,
  className           : string | undefined,
  editionMode         : boolean | undefined,
  addOrDeleteEvent ?  : (eventIndex : number, action : 'edit' | 'remove')=> void,
  eventIndex          : number,
  isPastEvent         : boolean | undefined,
  isToday             : boolean | undefined,
  isNextEvent         : boolean | undefined,
  onChangeEventInfo ? : (updatedProductEvent : ProductEvent)=> void,
};

const allowedEventTypes = [
  EventTypesEnum.Coupon.toString(),
  EventTypesEnum.AutocallAndCoupon.toString(),
  EventTypesEnum.FinalCoupon.toString(),
  EventTypesEnum.FinalFixing.toString(),
  EventTypesEnum.ToSet.toString(),
];

const CalendarEventCard = ({
  event, className, editionMode, eventIndex, addOrDeleteEvent: onChangeEvent, isPastEvent, isToday, isNextEvent, onChangeEventInfo,
} : CalendarEventCardProps) : React.ReactNode => {
  const labelClassName = labelToClassName.get(event.eventType) ?? '';

  const showOutcome = allowedEventTypes.includes(event.eventType);
  const outcomeValue = isNumberString(String(event.outcomeValue)) ? `${Number(event.outcomeValue)}%` : event.outcomeValue;

  return (
    isToday
      ? <p className = {'timeline__item__label'}>{event.eventType}</p>
      : (
        <div className = {`timeline__item timeline__item${labelClassName} ${className ?? ''} 
        ${isPastEvent && 'timeline__item--passed'} ${isNextEvent && 'timeline__item--nextEvent'}`}
        >
          {editionMode && (
            <Space className = {'timeline__item__actions'}>
              <Button
                size = {'small'}
                icon = {<EditFilled size = {12} />}
                type = {'primary'}
                style = {{
                  width  : 20,
                  height : 20,
                }}
                styles = {{
                  icon : {
                    width  : 12,
                    height : 12,
                  },
                }}
                onClick = {() : void => onChangeEvent && onChangeEvent(eventIndex, 'edit')}
              />

              <Button
                size = {'small'}
                icon = {<DeleteFilled />}
                type = {'primary'}
                style = {{
                  width  : 20,
                  height : 20,
                }}
                styles = {{
                  icon : {
                    width  : 12,
                    height : 12,
                  },
                }}
                onClick = {() : void => onChangeEvent && onChangeEvent(eventIndex, 'remove')}
              />
            </Space>
          )}

          {isNextEvent && <p className = {'timeline__item__value'}>Next Event</p>}

          <p className = {'timeline__item__label'}>{event.eventType}</p>

          {showOutcome
            ? (
              <>
                <Flex gap = {4} align = {'center'}>
                  <span className = {'timeline__item__flow-label'}>{isPastEvent ? 'Payment: ' : 'Potential flow: '}</span>

                  <p className = {'timeline__item__value'}>

                    {outcomeValue ? outcomeValue : '-'}

                  </p>

                </Flex>

                {event.autocallTrigger
                  ? (
                    <Flex gap = {4} align = {'center'}>
                      <span className = {'timeline__item__flow-label'}>
                        Autocall Trigger:
                      </span>

                      <EditableText
                        editable = {Boolean(editionMode)}
                        defaultValue = {event.autocallTrigger.toString()}
                        valueType = {'number'}
                        changeCallback = {(changedValue) : void => onChangeEventInfo && onChangeEventInfo({
                          ...event,
                          autocallTrigger : Number(changedValue),
                        })}
                      />
                    </Flex>
                  )
                  : null}

                {event.couponTrigger
                  ? (
                    <Flex gap = {4} align = {'center'}>
                      <span className = {'timeline__item__flow-label'}>
                        Coupon Trigger:
                      </span>

                      <EditableText
                        editable = {Boolean(editionMode)}
                        defaultValue = {event.couponTrigger.toString()}
                        valueType = {'number'}
                        changeCallback = {(changedValue) : void => onChangeEventInfo && onChangeEventInfo({
                          ...event,
                          couponTrigger : Number(changedValue),
                        })}
                      />
                    </Flex>
                  )
                  : null}
              </>
            )
            : null}

        </div>
      )
  );
};

export { CalendarEventCard };

