import { EventStatuslEnum, EventTypesEnum, FrequencyEnum } from '../../../data/product/productEnum';

import type { ProductEvent } from '../../../data/ProductType';

export const getFrequencyMultiplier = (frequency : string) : number => {
  switch (frequency) {
    case FrequencyEnum.Annual.toString():
      return 1;
    case FrequencyEnum.SemiAnnual.toString():
      return 2;
    case FrequencyEnum.Quarterly.toString():
      return 4;
    case FrequencyEnum.Monthly.toString():
      return 12;
    default:
      return 1;
  }
};

// Filter events to only include coupon and autocall events and set the status of the events based on the current date
export const cleanEvents = (events : ProductEvent[]) : ProductEvent[] => {
  const autocallAndCouponEvents = events.filter((event) => [EventTypesEnum.Coupon, EventTypesEnum.AutocallAndCoupon]
    .includes(event.eventType as EventTypesEnum));

  return autocallAndCouponEvents.map((event) => {
    if (new Date(event.valuationDate).getTime() < new Date().getTime()) {
      event.status = EventStatuslEnum.Past;
    }
    const nextEvent = autocallAndCouponEvents.find((e) => new Date(e.valuationDate).getTime() > new Date().getTime());
    if (nextEvent && nextEvent.valuationDate === event.valuationDate) {
      event.status = EventStatuslEnum.NextEvent;
    }
    return event;
  });
};

/** Extracts all values of 'autocallTrigger' or 'couponTrigger'
 * @param events ProductEvent list
 * @param field 'autocallTrigger' or 'couponTrigger'
 * @returns extracted values
 */
export const extractStepdownValues = (events : ProductEvent[], field : 'autocallTrigger' | 'couponTrigger') : number[] => events
  .filter((e) => e[field]).map((e) => e[field] as number);
