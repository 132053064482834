import { isObject, merge } from 'lodash';

import { CONFIG_BACKEND_SERVER_URL } from './config';
import { keycloakInstance } from './keycloak';

const buildQueryString = (querryObject : unknown, prefix = '') : string => {
  let result = '';
  if (isObject(querryObject)) {
    result = Object.entries(querryObject)
      .reduce(
        (acc, [key, val]) => acc
          + ((acc && !acc.endsWith('&') && val) ? '&' : '')
          + buildQueryString(val, prefix ? `${prefix}.${key}` : key),
        ''
      );
  } else if (prefix && querryObject) {
    result = `${prefix}=${encodeURIComponent(querryObject as string)}`;
  }
  return result;
};

export async function myFetch<T> (
  method : 'GET' | 'POST' | 'PUT' | 'DELETE',
  url : string,
  {
    body,
    query,
  } : {
    body  : object | FormData | null,
    query : object | null,
  } = {
    body  : null,
    query : null,
  },
  options : RequestInit = {}
) : Promise<T> {
  let completeUrl = CONFIG_BACKEND_SERVER_URL + url;
  const queryString = buildQueryString(query);
  if (queryString) {
    completeUrl = completeUrl + ((url.includes('?') ? '&' : '?') + queryString);
  }

  const isFormData = body instanceof FormData;

  const completeOptions = merge(
    {
      method,
      credentials : 'include',
      signal      : AbortSignal.timeout(30_000), // TODO env var
    },
    {
      headers : {
        ...isFormData ? {} : { 'Content-Type' : isObject(body) ? 'application/json' : 'text/plain' },
        Authorization : keycloakInstance.token,
        currentDate   : Date.now(),
      },
    },
    options
  );

  if (body) {
    completeOptions.body = isFormData ? body : JSON.stringify(body);
  }

  try {
    const response = await window.fetch(completeUrl, completeOptions);
    const isSuccess = response.status >= 200 && response.status < 300;

    if (!isSuccess || !response.ok) {
      // TODO : Custom error handling
      console.error('Server Error: ', response.status, response.statusText);
      return await Promise.reject<T>(new Error('Server Error'));
    }

    const contentType = response.headers.get('Content-Type');

    let formatedResponse : T;
    if (contentType?.includes('application/json')) {
      formatedResponse = await response.json() as T;
    } else if (contentType?.includes('text/plain')) {
      formatedResponse = await response.text() as T;
    } else {
      formatedResponse = null as T;
    }

    return await Promise.resolve<T>(formatedResponse);

  } catch (error : unknown) {
    console.error(`"${(error as Error).message}" error when calling ${completeUrl}`);
    return Promise.reject(error as Error);
  }
}
