import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import {ConfigProvider, theme} from 'antd';
import locale from 'antd/locale/en_GB';

import {CreateProductPage} from './Modules/LifeCycleManagement/CreateProductPage/CreateProductPage';
import {CreateTradePage} from './Modules/LifeCycleManagement/CreateTradePage/CreateTradePage';
import {EndOfLifeProducts} from './Modules/LifeCycleManagement/EndOfLifeProducts/EndOfLifeProducts';
import {ProductEventManager} from './Modules/LifeCycleManagement/ProductEventManager/ProductEventManager';
import {ProductPage} from './Modules/LifeCycleManagement/ProductPage/ProductPage';
import {ProductsList} from './Modules/LifeCycleManagement/ProductsList/ProductsList';
import {TradePage} from './Modules/LifeCycleManagement/TradePage/TradePage';
import {TradeList} from './Modules/LifeCycleManagement/TradesList/TradesList';
import {UnwindPage} from './Modules/LifeCycleManagement/UnwindPage/UnwindPage';
import {PricingForm} from './Modules/Pricing/PricingForm/PricingForm';
import {PricingResult} from './Modules/Pricing/PricingResult/PricingResult';
import {AppLayout} from './Shared/layout/layout';
import {myFetch} from './config/api';
import {
  CreateProductPageRoute,
  CreateTradePageRoute,
  EditProductPageRoute,
  EditTradePageRoute,
  EndingProductsRoute,
  HomeRoute,
  PricingRoute,
  ProductEventManagerRoute,
  ProductPageRoute,
  ProductsRoute,
  TradePageRoute,
  TradesListRoute,
  UnwindPageRoute
} from './data/Routes';
import {ErrorPage} from './errors/error-page';
import reportWebVitals from './reportWebVitals';
import {
  expertGreen, goldenYellow, whiteColor
} from './styles/colors';


import type {Underlying} from './data/Underlying';


import './index.css';
import './index.scss';

import {Provider} from 'react-redux';

import store from './store/store';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const router = createBrowserRouter([
  {
    path         : HomeRoute.path,
    element      : <AppLayout />,
    errorElement : <ErrorPage />,
    children     : [
      {
        path         : PricingRoute.path,
        element      : <PricingForm />,
        loader       : async () : Promise<Underlying[]> => myFetch('GET', '/price/underlyings'),
        errorElement : <ErrorPage />,
        children     : [
          {
            path    : ':priceId',
            element : <PricingResult />,
          },
        ],
      },
      {
        path    : ProductsRoute.path,
        element : <ProductsList />,
      },
      {
        path    : EndingProductsRoute.path,
        element : <EndOfLifeProducts />,
      },
      {
        path    : TradesListRoute.path,
        element : <TradeList />,
      },
      {
        path    : ProductEventManagerRoute.path,
        element : <ProductEventManager />,
      },
      {
        path    : CreateProductPageRoute.path,
        element : <CreateProductPage />,
      },
      {
        path    : EditProductPageRoute.path,
        element : <CreateProductPage />,
      },
      {
        path    : ProductPageRoute.path,
        element : <ProductPage />,
      },
      {
        path    : TradePageRoute.path,
        element : <TradePage />,
      },
      {
        path    : EditTradePageRoute.path,
        element : <CreateTradePage />,
      },
      {
        path    : CreateTradePageRoute.path,
        element : <CreateTradePage />,
      },
      {
        path    : UnwindPageRoute.path,
        element : <UnwindPage />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store = {store}>
      <ConfigProvider
        locale = {locale} // TODO: use i18n when translation will be ready
        theme = {{
          algorithm : theme.darkAlgorithm,
          token     : {
            fontFamily    : 'Work Sans, sans-serif',
            colorPrimary  : expertGreen,
            colorBgBase   : expertGreen,
            colorText     : whiteColor,
            colorTextBase : whiteColor,
            colorLink     : whiteColor,
            screenXSMax   : 360,
            screenXS      : 300,
            screenXSMin   : 280,
          },
            components : {
              Table : {
                cellFontSize      : 13,
                cellPaddingBlock  : 12,
                cellPaddingInline : 8,
              },
              Button : {
                fontSize   : 13,
                fontWeight : 600,
              },
              Select : {
                optionFontSize : 12,
                fontSize       : 13,
              },
              Menu : {
                horizontalItemSelectedColor : goldenYellow,
              },
            },
          }}
        >
          <RouterProvider router = {router} />
        </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

/* If you want to start measuring performance in your app, pass a function
   to log results (for example: reportWebVitals(console.log))
   or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
