import React, { useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Button, Flex, Input,
  Select,
  Space
} from 'antd';

import { myFetch } from '../../../../config/api';
import { mockPlaces, mockTickers } from '../../ProductPage/data';

import type { UnderlyingEquity } from '../../../../data/ProductType';

import './AddUnderlyingsForm.scss';

type CompanyItemType = {
  companyId       : number,
  companyName     : string,
  isin            : string,
  tickerWithPlace : string,
};

type EquityLikeFormType = {
  ticker      : string,
  place       : string,
  isin        : string,
  companyName : string,
  companyId   : number,
};

type AddUnderlyingsFormType = {
  onAddUnderlying : (equityLikeList : UnderlyingEquity[], underlyingRates : string[])=> void,
};

const emptyItem = {
  companyId   : 0,
  isin        : '',
  ticker      : '',
  place       : '',
  companyName : '',
};

const AddUnderlyingsForm = ({ onAddUnderlying } : AddUnderlyingsFormType) : React.ReactNode => {
  const [equityLikeList, setEquityLikeList] = useState<EquityLikeFormType[]>([emptyItem]);
  const [rates, setRates] = useState<string[]>([]);
  const [fetchingInfos, setFetchingInfos] = useState(false);

  useEffect(() : void => {
    const validEquityLike = equityLikeList
      .filter((item) => item.isin && item.companyId && item.ticker && item.place)
      .map((item) => ({
        isin            : item.isin,
        companyId       : item.companyId,
        tickerWithPlace : `${item.ticker}.${item.place}`,
        ticker          : item.ticker,
      }));

    const validRates = rates.filter((rate) => rate.length);
    onAddUnderlying(validEquityLike, validRates);
  }, [rates, equityLikeList]);

  const addNewEquityLike = () : void => {
    setEquityLikeList([...equityLikeList, emptyItem]);
  };

  const onChangeEquityLikeForm = (index : number, paramKey : keyof EquityLikeFormType, paramValue : string) : void => {

    const currentItem : EquityLikeFormType = {
      ...equityLikeList[index],
      [paramKey] : paramValue,
    };

    const newList = equityLikeList.map((item, i) => (i === index ? currentItem : item));

    setEquityLikeList(newList);

    // Try getting isin and company name from ticker and place
    if (currentItem.ticker && currentItem.place && !currentItem.isin) {
      setFetchingInfos(true);

      myFetch('GET', `/products/underlyings/ticker?tickerWithPlace=${currentItem.ticker}.${currentItem.place}`)
        .then((response) : void => {
          const fetchedInfos = response as CompanyItemType;
          const {
            isin, companyName, companyId,
          } = fetchedInfos;

          setEquityLikeList([...newList].map((item, i) => (i === index
            ? {
              ...item,
              companyId,
              isin,
              companyName,
            }
            : item)));
        })
        .catch(() : void => {
          setEquityLikeList([...newList].map((item, i) => (i === index
            ? {
              ...item,
              isin        : '',
              companyName : '',
              companyId   : 0,
            }
            : item)));
        })
        .finally(() => setFetchingInfos(false));
    }

    // Try getting ticker with place and company info from isin
    if (currentItem.isin && (!currentItem.place || !currentItem.ticker)) {
      myFetch('GET', `/companies/underlyings/isin?isin=${currentItem.isin}`)
        .then((response) : void => {
          const fetchedInfos = response as CompanyItemType;
          const {
            companyName, companyId, tickerWithPlace,
          } = fetchedInfos;

          newList[index] = {
            ...newList[index],
            companyId,
            companyName,
          };
          if (tickerWithPlace) {
            newList[index].ticker = tickerWithPlace.split('.')[0];
            newList[index].place = tickerWithPlace.split('.')[1];
          }

          setEquityLikeList([...newList]);
        })
        .catch(() : void => {
          setEquityLikeList([...newList].map((item, i) => (i === index
            ? {
              ...item,
              companyName : '',
              companyId   : 0,
            }
            : item)));
        })
        .finally(() => setFetchingInfos(false));
    }
  };

  return (
    <div className = {'add-underlyings-form'}>
      <p style = {{ marginBottom : '6px' }}>Underlyings</p>

      <Space>
        <Button
          disabled = {fetchingInfos}
          type = {'primary'}
          className = {'add-underlying-button'}
          onClick = {addNewEquityLike}
        >Add Equity Like
        </Button>

        <Button
          disabled = {fetchingInfos}
          type = {'primary'}
          className = {'add-underlying-button'}
          onClick = {() : void => setRates([...rates, ''])}
        >Add Rate
        </Button>

        <Button disabled type = {'primary'} className = {'add-underlying-button'}>Add CDS</Button>
      </Space>

      <div className = {'equity-like-form'}>

        {equityLikeList.map((item, index) => (
          <Flex vertical = {true} key = {index}>
            {item.companyName && <p>{item.companyName}</p>}

            <Space.Compact
              style = {{
                width        : '100%',
                marginBottom : '6px',
              }}
            >

              <Input
                className = {'product-form-input'}
                disabled = {fetchingInfos}
                value = {item.ticker}
                placeholder = {'Enter ticker'}
                style = {{ width : '30%' }}
                onChange = {(e) : void => onChangeEquityLikeForm(index, 'ticker', e.target.value)}
              />

              <Input
                className = {'product-form-input'}
                disabled = {fetchingInfos}
                value = {item.place}
                placeholder = {'Enter place'}
                style = {{ width : '30%' }}
                onChange = {(e) : void => onChangeEquityLikeForm(index, 'place', e.target.value)}
              />

              <Input
                className = {'product-form-input'}
                disabled = {fetchingInfos}
                value = {item.isin}
                placeholder = {'ISIN'}
                style = {{ width : '40%' }}
                onChange = {(e) : void => onChangeEquityLikeForm(index, 'isin', e.target.value)}
              />

              <Button
                type = {'text'}
                icon = {<DeleteOutlined />}
                onClick = {() : void => setEquityLikeList(equityLikeList.filter((_, i) => i !== index))}
              />
            </Space.Compact>
          </Flex>
        ))}

        {rates.map((item, index) => (
          <Flex
            key = {index}
            justify = {'space-between'}
            style = {{ marginBottom : '6px' }}
          >
            <Space.Compact style = {{ width : '100%' }}>
              <Input
                className = {'product-form-input'}
                value = {item}
                placeholder = {'Enter Underlying Rate'}
                style = {{ width : '100%' }}
                onChange = {(e) : void => setRates(rates.map((r, i) => (i === index ? e.target.value : r)))}
              />

              <Button
                type = {'text'}
                icon = {<DeleteOutlined />}
                onClick = {() : void => setRates(rates.filter((_, i) => i !== index))}
              />
            </Space.Compact>
          </Flex>
        ))}
      </div>

    </div>
  );
};

export { AddUnderlyingsForm };
