export enum TradeStatusClass {
  Booked = 'booked',
  'Order Received' = 'order__received',
  'Invoice Sent' = 'invoice__sent',
  'Commission to be paid' = 'commission__tobePaid',
  Closed = 'closed',
}

export enum EventTypesEnum {
  Coupon = 'Coupon',
  AutocallAndCoupon = 'Autocall & Coupon',
  Autocall = 'Autocall',
  InitialFixing = 'Initial Fixing',
  IssueDate = 'Issue Date',
  FinalFixing = 'Final Fixing',
  MaturityDate = 'Maturity',
  FinalCoupon = 'Final Coupon',
  ToSet = 'To Set',
}

export enum ProductListPageViewEnum {
  DEFAULT = 'Default',
  BY_CLIENT = 'Client',
  BY_REPRESENTATIVE = 'Representative',
  BY_PRODUCT_TYPE = 'Product Type',
  BY_VALORIZATION = 'Group By Valuation',
  BY_ISSUER = 'Issuer',
  BY_NEXT_EVENT = 'Next Event',
}

export enum EventStatuslEnum {
  Past = 'Past',
  NextEvent = 'Next event',
  Future = 'Future',
  Cancel = 'Cancel',
}

export enum FrequencyEnum {
  Annual = 'Annual',
  SemiAnnual = 'Semi-Annual',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
}

export enum ProductTypeEnum {
  Autocall = 'Autocall',
  Digital = 'Digital',
  CreditLinkedNote = 'Credit Linked Note',
  Participation = 'Participation',
  Rates = 'Rates',
  Custom = 'Custom',
}

export enum ProductSubTypeEnum {
  Phoenix = 'Phoenix',
  Classic = 'Classic',
  Reverse = 'Reverse',
  BoosterBonus = 'Booster/Bonus',
  TwinWin = 'Twin Win',
  ReverseConvertible = 'Reverse Convertible',
  SingleName = 'Single Name',
  Tranche = 'Tranche',
  TrancheKG = 'Tranche KG',
  Participation = 'Participation',
  TwoOptionsPayoff = '2 Options Payoff',
  SharkNote = 'Shark Note',
  FixedRateNote = 'Fixed Rate Note',
  FloatingRateNote = 'Floating Rate Note',
  FlooredFloater = 'Floored Floater',
  TarnSteepner = 'TARN Steepner',
  Custom = 'Custom',
}

export const productTypeMapping : { [key in ProductTypeEnum] : ProductSubTypeEnum[] } = {
  [ProductTypeEnum.Autocall] : [
    ProductSubTypeEnum.Phoenix,
    ProductSubTypeEnum.Classic,
    ProductSubTypeEnum.Reverse,
    ProductSubTypeEnum.BoosterBonus,
    ProductSubTypeEnum.TwinWin,
  ],
  [ProductTypeEnum.Digital] : [
    ProductSubTypeEnum.ReverseConvertible,
    ProductSubTypeEnum.Classic,
  ],
  [ProductTypeEnum.CreditLinkedNote] : [
    ProductSubTypeEnum.TrancheKG,
    ProductSubTypeEnum.SingleName,
    ProductSubTypeEnum.Tranche,
  ],
  [ProductTypeEnum.Participation] : [
    ProductSubTypeEnum.TwoOptionsPayoff,
    ProductSubTypeEnum.SharkNote,
    ProductSubTypeEnum.BoosterBonus,
    ProductSubTypeEnum.TwinWin,
  ],
  [ProductTypeEnum.Rates] : [
    ProductSubTypeEnum.FixedRateNote,
    ProductSubTypeEnum.FloatingRateNote,
    ProductSubTypeEnum.FlooredFloater,
    ProductSubTypeEnum.TarnSteepner,
  ],
  [ProductTypeEnum.Custom] : [ProductSubTypeEnum.Custom],
};

export enum BasketTypeEnum {
  SingleStock = 'Single Stock',
  WorstOf = 'Worst Of',
  BestOf = 'Best Of',
  WeightedAverage = 'Weighted Average',
}

export enum BarrierTypeEnum {
  European = 'European',
  UsClose = 'Us Close',
  UsIntraday = 'Us Intraday',
}

export const eventTypesList = [
  EventTypesEnum.AutocallAndCoupon,
  EventTypesEnum.Coupon,
  EventTypesEnum.InitialFixing,
  EventTypesEnum.IssueDate,
  EventTypesEnum.FinalFixing,
  EventTypesEnum.MaturityDate,
];
