import type { valueType } from 'antd/es/statistic/utils';

import { BarrierTypeEnum, FrequencyEnum } from '../../../data/product/productEnum';

import type { ProductFormFieldType } from '../../../data/ProductType';

type ProductTypeMapping = {
  [key : string] : string[],
};

type ProductFieldMapType = { [key : string] : ProductFieldType[] };

type ProductFieldType = {
  fieldName           : string,
  fieldKey            : keyof ProductFormFieldType,
  fieldType           : 'number' | 'select' | 'currency' | 'toggle' | 'date',
  options ?           : (string | number)[],
  suffix ?: string,
  defaultValue ?: valueType,
  required ?: boolean,
  allowedProductTypes : ProductTypeMapping[],
  disabled ?: boolean,
};

const productFields : {[key : string] : ProductFieldType[] } = {
  coupon : [
    {
      fieldName           : 'Coupon',
      fieldKey            : 'coupon',
      fieldType           : 'number',
      suffix              : '% p. a.',
      required            : true,
      allowedProductTypes : [
        { Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']},
        { Digital : ['Classic', 'Reverse Convertible']},
        { 'Credit Linked Note' : ['Tranche KG', 'Tranche', 'Single Name']},
        { Rates : ['Fixed Rate Note', 'Floating Rate Note', 'Floored Floater', 'TARN Steepner']},
      ],
    },
    {
      fieldName           : 'Frequency',
      fieldKey            : 'frequency',
      fieldType           : 'select',
      options             : Object.values(FrequencyEnum).map((key) => key),
      required            : true,
      allowedProductTypes : [
        { Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']},
        { 'Credit Linked Note' : ['Tranche KG', 'Tranche', 'Single Name']},
        { Rates : ['Fixed Rate Note', 'Floating Rate Note', 'Floored Floater', 'TARN Steepner']},
      ],
    },
    {
      fieldName           : 'Coupon Trigger',
      fieldKey            : 'couponTrigger',
      fieldType           : 'number',
      suffix              : '%',
      required            : true,
      allowedProductTypes : [
        { Autocall : ['Phoenix']},
        { Digital : ['Classic']},
      ],
    },
    {
      fieldName           : 'Coupon Stepdown',
      fieldKey            : 'couponStepdown',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      allowedProductTypes : [{ Autocall : ['Phoenix']}, { Digital : ['Classic', 'Reverse Convertible']}],
    },
    {
      fieldName           : 'Memory Effect',
      fieldKey            : 'couponMemoryEffect',
      fieldType           : 'toggle',
      required            : false,
      allowedProductTypes : [
        { Autocall : ['Phoenix']},
        { Digital : ['Reverse Convertible']},
      ],
    },
    {
      fieldName           : 'Cap',
      fieldKey            : 'couponCap',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      allowedProductTypes : [{ Rates : ['Floored Floater']}],
    },
    {
      fieldName           : 'Floor',
      fieldKey            : 'hasCouponFloor',
      fieldType           : 'toggle',
      required            : false,
      allowedProductTypes : [
        { Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']},
        { Digital : ['Classic']},
      ],
    },
    {
      fieldName           : 'Coupon Floor',
      fieldKey            : 'couponFloor',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      allowedProductTypes : [
        { Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']},
        { Digital : ['Reverse Convertible', 'Classic']},
      ],
    },
    {
      fieldName           : 'Floor',
      fieldKey            : 'floorValue',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      allowedProductTypes : [{ Rates : ['Floored Floater']}],
    },
    {
      fieldName           : 'Target Coupon',
      fieldKey            : 'targetCoupon',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      allowedProductTypes : [{ Rates : ['TARN Steepner']}],
    },
    {
      fieldName           : 'First Floating Payment',
      fieldKey            : 'firstFloatingPaymentDate',
      fieldType           : 'date',
      required            : false,
      allowedProductTypes : [{ Rates : ['TARN Steepner']}],
    },
  ],
  autocall : [
    {
      fieldName           : 'Autocall Trigger',
      fieldKey            : 'autocallTrigger',
      fieldType           : 'number',
      suffix              : '%',
      required            : true,
      allowedProductTypes : [{ Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']}],
    },
    {
      fieldName           : 'Autocall Stepdown',
      fieldKey            : 'autocallStepdown',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      allowedProductTypes : [{ Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']}],
    },
    {
      fieldName           : 'NonCall',
      fieldKey            : 'nonCallPeriod',
      fieldType           : 'select',
      options             : Array.from({ length : 21 }, (_, i) => i),
      required            : false,
      allowedProductTypes : [{ Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']}],
    },
    {
      fieldName           : 'Floor',
      fieldKey            : 'hasAutocallFloor',
      fieldType           : 'toggle',
      required            : false,
      allowedProductTypes : [{ Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']}],
    },
    {
      fieldName           : 'Autocall Floor',
      fieldKey            : 'autocallFloor',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      allowedProductTypes : [{ Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']}],
    },
  ],
  protection : [
    {
      fieldName           : 'Barrier Type',
      fieldKey            : 'barrierType',
      fieldType           : 'select',
      options             : Object.values(BarrierTypeEnum),
      required            : true,
      allowedProductTypes : [
        { Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']},
        { Digital : ['Reverse Convertible', 'Classic']},
      ],
    },
    {
      fieldName           : 'Barrier Level',
      fieldKey            : 'barrierLevel',
      fieldType           : 'number',
      suffix              : '%',
      required            : true,
      allowedProductTypes : [
        { Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']},
        { Digital : ['Reverse Convertible', 'Classic']},
      ],
    },
    {
      fieldName           : 'Strike',
      fieldKey            : 'strike',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      allowedProductTypes : [
        { Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']},
        { Digital : ['Reverse Convertible', 'Classic']},
      ],
    },
    {
      fieldName           : 'Downside Leverage',
      fieldKey            : 'downsideLeverage',
      fieldType           : 'number',
      suffix              : '%',
      required            : false,
      disabled            : true,
      allowedProductTypes : [
        { Autocall : ['Phoenix', 'Classic', 'Reverse', 'Booster/Bonus', 'Twin Win']},
        { Digital : ['Reverse Convertible', 'Classic']},
      ],
    },
  ],
  maturity : [
    {
      fieldName           : 'Bonus Level',
      fieldKey            : 'bonusLevel',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 0,
      required            : true,
      allowedProductTypes : [{ Autocall : ['Booster/Bonus']}, { Participation : ['Booster/Bonus']}],
    },
    {
      fieldName           : 'Bonus Barrier',
      fieldKey            : 'bonusBarrier',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : true,
      allowedProductTypes : [{ Autocall : ['Booster/Bonus']}, { Participation : ['Booster/Bonus']}],
    },
    {
      fieldName           : 'Upside Leverage',
      fieldKey            : 'upsideLeverage',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : true,
      allowedProductTypes : [{ Autocall : ['Booster/Bonus', 'Twin Win']}, { Participation : ['Booster/Bonus', 'Twin Win', 'Shark Note']}],
    },
    {
      fieldName           : 'Downside Leverage',
      fieldKey            : 'maturityDownsideLeverage',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : true,
      allowedProductTypes : [{ Autocall : ['Twin Win']}, { Participation : ['Twin Win']}],
    },
    {
      fieldName           : 'Upside Strike',
      fieldKey            : 'upsideStrike',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : true,
      allowedProductTypes : [{ Autocall : ['Twin Win', 'Booster/Bonus']}, { Participation : ['Twin Win']}],
    },
    {
      fieldName           : 'Downside Strike',
      fieldKey            : 'downsideStrike',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : true,
      allowedProductTypes : [{ Autocall : ['Twin Win']}, { Participation : ['Twin Win']}],
    },
    {
      fieldName           : 'Has Cap',
      fieldKey            : 'hasMaturityCap',
      fieldType           : 'toggle',
      required            : false,
      allowedProductTypes : [{ Autocall : ['Booster/Bonus', 'Twin Win']}, { Participation : ['Booster/Bonus', 'Twin Win']}],
    },
    {
      fieldName           : 'Cap',
      fieldKey            : 'maturityCap',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : false,
      allowedProductTypes : [{ Autocall : ['Booster/Bonus', 'Twin Win']}, { Participation : ['Booster/Bonus', 'Twin Win']}],
    },
    {
      fieldName           : 'Capital Guarantee Level',
      fieldKey            : 'capitalGuaranteeLevel',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : true,
      allowedProductTypes : [{ Participation : ['Shark Note', '2 Options Payoff']}],
    },
    {
      fieldName           : 'Strike',
      fieldKey            : 'participationStrike',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : true,
      allowedProductTypes : [{ Participation : ['Shark Note']}],
    },
    {
      fieldName           : 'Has Rebate',
      fieldKey            : 'hasMaturityRebate',
      fieldType           : 'toggle',
      required            : false,
      allowedProductTypes : [{ Autocall : ['Booster/Bonus', 'Twin Win']}, { Participation : ['Booster/Bonus', 'Twin Win']}],
    },
    {
      fieldName           : 'Rebate',
      fieldKey            : 'rebate',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : false,
      allowedProductTypes : [{ Autocall : ['Booster/Bonus', 'Twin Win']}, { Participation : ['Booster/Bonus', 'Twin Win']}],
    },
    {
      fieldName           : 'Rebate Barrier',
      fieldKey            : 'rebateBarrier',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 150,
      required            : false,
      allowedProductTypes : [{ Autocall : ['Booster/Bonus', 'Twin Win']}, { Participation : ['Booster/Bonus', 'Twin Win', 'Shark Note']}],
    },
    {
      fieldName           : 'Rebate Barrier Type',
      fieldKey            : 'rebateBarrierType',
      fieldType           : 'select',
      options             : Object.values(BarrierTypeEnum),
      allowedProductTypes : [{ Autocall : ['Booster/Bonus', 'Twin Win']}, { Participation : ['Booster/Bonus', 'Twin Win', 'Shark Note']}],
    },
  ],
  creditLinkedNote : [
    {
      fieldName           : 'Attachment Point',
      fieldKey            : 'attachmentPoint',
      fieldType           : 'number',
      suffix              : '%',
      allowedProductTypes : [{ 'Credit Linked Note' : ['Tranche KG', 'Tranche']}],
    },
    {
      fieldName           : 'Detachment Point',
      fieldKey            : 'detachmentPoint',
      fieldType           : 'number',
      suffix              : '%',
      allowedProductTypes : [{ 'Credit Linked Note' : ['Tranche KG', 'Tranche']}],
    },
    {
      fieldName           : 'Recovery Type',
      fieldKey            : 'recoveryType',
      fieldType           : 'select',
      options             : ['Floating', 'Zero'],
      allowedProductTypes : [{ 'Credit Linked Note' : ['Tranche KG', 'Tranche', 'Single Name']}],
    },
    {
      fieldName           : 'Callable',
      fieldKey            : 'callable',
      fieldType           : 'toggle',
      allowedProductTypes : [{ 'Credit Linked Note' : ['Single Name']}],
    },
    {
      fieldName           : 'First Coupon Payment',
      fieldKey            : 'firstCouponPaymentDate',
      fieldType           : 'date',
      allowedProductTypes : [{ 'Credit Linked Note' : ['Tranche', 'Tranche KG', 'Single Name']}],
    },
  ],
  option : [
    {
      fieldName           : 'Option type',
      fieldKey            : 'optionType',
      fieldType           : 'select',
      options             : ['Call', 'Put'],
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Option Position',
      fieldKey            : 'optionPosition',
      fieldType           : 'select',
      options             : ['Long', 'Short'],
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Strike',
      fieldKey            : 'optionStrike',
      fieldType           : 'number',
      suffix              : '%',
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Leverage',
      fieldKey            : 'optionLeverage',
      fieldType           : 'number',
      suffix              : '%',
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Rebate',
      fieldKey            : 'optionRebate',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : false,
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Barrier Level',
      fieldKey            : 'optionBarrierLevel',
      fieldType           : 'number',
      suffix              : '%',
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Barrier Type',
      fieldKey            : 'optionBarrierType',
      fieldType           : 'select',
      options             : Object.values(BarrierTypeEnum),
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Has Barrier',
      fieldKey            : 'optionAsBarrier',
      fieldType           : 'toggle',
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
  ],
  option2 : [
    {
      fieldName           : 'Option type',
      fieldKey            : 'optionType2',
      fieldType           : 'select',
      options             : ['Call', 'Put'],
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Option Position',
      fieldKey            : 'optionPosition2',
      fieldType           : 'select',
      options             : ['Long', 'Short'],
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Strike',
      fieldKey            : 'optionStrike2',
      fieldType           : 'number',
      suffix              : '%',
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Leverage',
      fieldKey            : 'optionLeverage2',
      fieldType           : 'number',
      suffix              : '%',
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Rebate',
      fieldKey            : 'optionRebate2',
      fieldType           : 'number',
      suffix              : '%',
      defaultValue        : 100,
      required            : false,
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Barrier Level',
      fieldKey            : 'optionBarrierLevel2',
      fieldType           : 'number',
      suffix              : '%',
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Barrier Type',
      fieldKey            : 'optionBarrierType2',
      fieldType           : 'select',
      options             : Object.values(BarrierTypeEnum),
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
    {
      fieldName           : 'Has Barrier',
      fieldKey            : 'optionAsBarrier2',
      fieldType           : 'toggle',
      allowedProductTypes : [{ Participation : ['2 Options Payoff']}],
    },
  ],
};

/**
 * Returns a the product input fields, with string key grouping (like autocall or protection)
 * @param productType string - Product type.
 * @param productSubType string - Product sub-type
 * @returns A ProductFieldMapType.
 */
export const getProductInputFields = (
  productType : string,
  productSubType : string
) : ProductFieldMapType => Object.entries(productFields)
  .reduce<ProductFieldMapType>((acc : ProductFieldMapType, curr : [string, ProductFieldType[]]) => {
    acc[curr[0]] = curr[1].filter((field) => field.allowedProductTypes.some(
      (prop : ProductTypeMapping) => (prop[productType] ?? []).includes(productSubType)
    ));
    return acc;
  }, {});
