import { round } from 'lodash';

export const parsePercentStringToFloat = (result : string) : number => round(parseFloat(result.trim().replace('%', '')), 2);

export const isNumberString = (str : string) : boolean => {
  const cleanStr = str.trim();
  if (!cleanStr) {
    return false;
  }

  return !isNaN(Number(cleanStr));
};

/**
 * Converts camelCase string to title case string. E.g. "camelCaseString" -> "Camel Case String"
 * @param {string} camelCaseString - string to convert
 * @returns {string} - converted string
 */
export const toTitleCase = (camelCaseString : string) : string => camelCaseString
  .replace(/([A-Z])/g, ' $1')
  .replace(/^./, (str) => str.toUpperCase());
