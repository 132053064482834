import React, { useEffect, useRef, useState } from 'react';
import type { ComponentProps } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { CloseOutlined, FileOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Upload,
  message
} from 'antd';
import type { FormProps, InputRef } from 'antd';

import dayjs from 'dayjs';

import { CalendarEvents } from '../../../Shared/CalendarEvents/CalendarEvents';
import { CurrencyInput } from '../../../Shared/CurrencyInput/CurrencyInput';
import { MainTitle } from '../../../Shared/MainTitle/MainTitle';
import { myFetch } from '../../../config/api';
import { EditProductPageRoute } from '../../../data/Routes';
import {
  BasketTypeEnum, EventTypesEnum, ProductSubTypeEnum, ProductTypeEnum,
  eventTypesList, productTypeMapping
} from '../../../data/product/productEnum';
import { getIssuers, getSingleProduct } from '../../../store/productsStore/productApi';
import { toTitleCase } from '../../../utils/stringUtils';
import { dbCurrencyData } from '../ProductPage/data';
import { createRedemptionsList } from '../lifecycleHelpers/createProductRedemption';
import { cleanEvents, extractStepdownValues, getFrequencyMultiplier } from '../lifecycleHelpers/lifecycleHelpers';
import { useAutoCreateEvent } from '../lifecycleHooks/autoCreateEventHook';
import { useCreateBasicProductEvents } from '../lifecycleHooks/useCreateInitProductEvents';

import { AddUnderlyingsForm } from './AddUnderlyingsForm/AddUnderlyingsForm';
import { getProductInputFields } from './productInputFields';

import type {
  ProductApiSubmissionForm,
  ProductEvent, ProductField, ProductFormFieldType,
  UnderlyingEquity
} from '../../../data/ProductType';
import type { AppDispatch, RootStateType } from '../../../store/store';
import type { UploadRequestError, UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

import './CreateProductPage.scss';

const CreateProductPage = () : React.ReactNode => {
  const [form] = Form.useForm<ProductFormFieldType>();
  const inputEventDateRef : ComponentProps<typeof DatePicker>['ref'] = useRef(null);
  const [currentEventIndex, setCurrentEventIndex] = useState<number | null>(null);

  const maturityLength = Form.useWatch<number>('maturityLength', form);
  const issueDate = Form.useWatch<string>('issueDate', form);
  const productType = Form.useWatch<string>('productType', form);
  const productSubType = Form.useWatch<string>('productSubType', form);
  const frequency = Form.useWatch<string>('frequency', form);
  const hasCouponFloor = Form.useWatch<boolean>('hasCouponFloor', form);
  const hasAutocallFloor = Form.useWatch<boolean>('hasAutocallFloor', form);
  const initialFixingDate = Form.useWatch<string>('initialFixingDate', form);
  const optionAsBarrier = Form.useWatch<boolean>('optionAsBarrier', form);
  const hasMaturityCap = Form.useWatch<boolean>('hasMaturityCap', form);
  const hasMaturityRebate = Form.useWatch<boolean>('hasMaturityRebate', form);
  const hasSecondOptionBarrier = Form.useWatch<boolean>('optionAsBarrier2', form);
  const strike = Form.useWatch<number>('strike', form);
  const nonCallPeriod = Form.useWatch<number>('nonCallPeriod', form);
  const coupon = Form.useWatch<number>('coupon', form);
  const barrierLevel = Form.useWatch<number>('barrierLevel', form);
  const couponStepdown = Form.useWatch<number>('couponStepdown', form);
  const couponTrigger = Form.useWatch<number>('couponTrigger', form);
  const autocallTrigger = Form.useWatch<number>('autocallTrigger', form);
  const autocallStepdown = Form.useWatch<number>('autocallStepdown', form);

  const [items, setItems] = useState<string[]>(eventTypesList);
  const [name, setName] = useState('');
  const [showSecondOptionsPayOff, setShowSecondOptionsPayOff] = useState<boolean>(false);
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const defaultCustomFieldValues : ProductField[] = [
    {
      id          : 1,
      customKey   : '',
      customValue : '',
    },
  ];
  const [customFields, setCustomFields] = useState<ProductField[]>(defaultCustomFieldValues);
  const [calendarEvents, setCalendarEvents] = useState<ProductEvent[]>([]);
  const [calendarEventForm, setCalendarEventForm] = useState<ProductEvent>({
    id            : 1,
    eventType     : '',
    paymentDate   : '',
    valuationDate : '',
    outcomeValue  : '',
    status        : 'Future',
  });

  const [isCreating, setIsCreating] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [underlyingsRates, setUnderlyingsRates] = useState<string[]>([]);
  const [underlyingsEquity, setUnderlyingsEquity] = useState<UnderlyingEquity[]>([]);

  const productFields = getProductInputFields(productType, productSubType);

  const issuers = useSelector((state : RootStateType) => state.productsReducer.issuers);
  const productItem = useSelector((state : RootStateType) => state.productsReducer.product);
  const {
    isLoading, item: product,
  } = productItem;

  const onNameChange = (event : React.ChangeEvent<HTMLInputElement>) : void => {
    setName(event.target.value);
  };

  const addItem = (e : React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) : void => {
    e.preventDefault();
    setItems([...items, name]);
    setName('');
    inputRef.current?.focus();
  };

  const { productId } = useParams<{ productId : string }>();

  const isEditing = Boolean(useMatch({ path : EditProductPageRoute.path }));

  useEffect(() => {
    void dispatch(getIssuers());

    if (!productId) {
      return;
    }
    if (isEditing) {
      void dispatch(getSingleProduct({ productId }));
    }
  }, []);

  useEffect(() => {
    // When the product type changes, set the first sub type as the default selected
    if (!productType) {
      return;
    }
    form.setFieldsValue({ productSubType : productTypeMapping[productType as ProductTypeEnum][0] });
  }, [productType]);

  useEffect(() => {
    if (!strike) {
      return;
    }
    form.setFieldsValue({ downsideLeverage : parseFloat(((100 / Number(strike)) * 100).toFixed(2)) });
  }, [strike]);

  const addNewCustomField = () : void => {
    setCustomFields([
      ...customFields,
      ...defaultCustomFieldValues,
    ]);
  };

  const onChangeInputOptions = (paramKey : keyof ProductField, paramValue : string, paramIndex : number) : void => {
    setCustomFields(
      customFields.map((field, index) => {
        if (paramIndex === index) {
          return {
            ...field,
            [paramKey] : paramValue,
          };
        }
        return field;
      })
    );
  };

  const resetEventForm = () : void => {
    setCalendarEventForm({
      id            : calendarEvents.length + 1,
      eventType     : '',
      paymentDate   : '',
      valuationDate : '',
      outcomeValue  : '',
      status        : 'Future',
    });
  };

  const onAddCalendarEvent = () : void => {
    const {
      eventType, valuationDate,
    } = calendarEventForm;

    if (!eventType || !valuationDate) {
      return;
    }

    // add and sort the events by date
    const updatedCalendarEvents = [
      ...calendarEvents,
      calendarEventForm,
    ];
    setCalendarEvents(updatedCalendarEvents);

    // reset the form inputs
    resetEventForm();
  };

  const onUpdateCalendarEvent = () : void => {
    setCalendarEvents(calendarEvents.map((event, index) => (index === currentEventIndex ? calendarEventForm : event)));
    setCurrentEventIndex(null);
    resetEventForm();
  };

  const onChangecalendarEventForm = (key : string, inputValue : string | number) : void => {
    setCalendarEventForm({
      ...calendarEventForm,
      [key] : inputValue,
    });
  };

  const onChangeCalendarEvent = (eventIndex : number, action : 'edit' | 'remove') : void => {
    if (action === 'remove') {
      setCalendarEvents(calendarEvents.filter((_, index) => eventIndex !== index));
    } else {
      setCurrentEventIndex(eventIndex);
      setCalendarEventForm(calendarEvents[eventIndex]);
      inputEventDateRef.current?.focus();
    }
  };

  const onAddUnderlying = (equityLikeList : UnderlyingEquity[], rates : string[]) : void => {
    setUnderlyingsRates(rates);
    setUnderlyingsEquity(equityLikeList);
  };

  const updloadTermsheet = ({
    file, onError, onSuccess,
  } : Pick<RcCustomRequestOptions, 'file' | 'onError' | 'onSuccess'>) : void => {
    const formData = new FormData();
    formData.append('file', file as File);

    myFetch('POST', '/utilities/upload-file', {
      body  : formData,
      query : null,
    }).then((response) => {
      const { fileUrl } = response as { fileUrl : string };
      form.setFieldsValue({
        termSheet : fileUrl,
      });
      if (onSuccess) {
        onSuccess(response);
      }
    })
      .catch((e : unknown) : void => {
        if (onError) {
          onError(e as UploadRequestError);
        }
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : 'Failed to upload termsheet, please try again',
          });
        }
      });
  };

  const onChangeCalendarEventInfo = (updatedEvent : ProductEvent) : void => {
    setCalendarEvents(
      calendarEvents.map((event) => (event.id === updatedEvent.id ? { ...updatedEvent } : { ...event }))
    );
  };

  const onFinish : FormProps<ProductFormFieldType>['onFinish'] = (values : ProductFormFieldType) : void => {
    setIsCreating(true);

    if (!values.termSheet) {
      messageApi.open({
        type    : 'error',
        content : 'Termsheet is required',
      });
      setIsCreating(false);
      return;
    }

    let newProduct : ProductApiSubmissionForm = {
      isin           : values.isin,
      name           : values.name,
      issuerId       : values.issuerId,
      productType    : values.productType,
      productSubType : values.productSubType,
      currencyRefId  : values.currencyRefId,
      termSheet      : values.termSheet,
      basketType     : values.basketType,
      status         : 'Alive',
      totalDuration  : values.maturityLength,
      maturityDate   : dayjs(issueDate).add(maturityLength, 'month')
        .format('YYYY-MM-DD'),
      issueDate            : dayjs(issueDate).format('YYYY-MM-DD'),
      initialValuationDate : dayjs(initialFixingDate).format('YYYY-MM-DD'),
      underlyings          : underlyingsEquity,
      underlyingsRates,
      strike               : values.strike,
      downsideLeverage     : values.downsideLeverage,
      events               : cleanEvents(calendarEvents),
      fields               : customFields.filter((field) => field.customKey && field.customValue),
    };

    let otherCustomFields : ProductApiSubmissionForm['fields'] = [];

    if (productType === ProductTypeEnum.Autocall.toString()) {
      const redemptions = createRedemptionsList(form, {
        productType,
        productSubType,
        hasMaturityCap,
        hasMaturityRebate,
        barrierType : values.barrierType,
        strike,
      });

      newProduct.redemptions = redemptions;

      newProduct.autocall = {
        nonCallPeriod  : values.nonCallPeriod,
        initialBarrier : values.autocallTrigger,
        stepdown       : values.autocallStepdown || 0,
        barrierList    : extractStepdownValues(calendarEvents, 'autocallTrigger'),
      };

      if (hasAutocallFloor) {
        newProduct.autocall = {
          ...newProduct.autocall,
          autocallFloor : values.autocallFloor,
        };
      }

      if (productSubType === ProductSubTypeEnum.Phoenix.toString()) {
        newProduct.couponBarrier = {
          memoryEffect   : values.couponMemoryEffect,
          initialBarrier : values.autocallTrigger,
          stepdown       : values.autocallStepdown || 0,
          barrierList    : extractStepdownValues(calendarEvents, 'couponTrigger'),
        };
      }

      // Save protection and maturity fields as custom product fields
      otherCustomFields = productFields.protection.filter((field) => form.getFieldValue(field.fieldKey)).map((field) => ({
        customKey   : `Protection ${field.fieldName}`,
        customValue : String(form.getFieldValue(field.fieldKey)),
      }));

      if (productSubType === ProductSubTypeEnum.BoosterBonus.toString() || productSubType === ProductSubTypeEnum.TwinWin.toString()) {
        otherCustomFields = [
          ...otherCustomFields,
          ...productFields.maturity.filter((field) => form.getFieldValue(field.fieldKey)).map((field) => ({
            customKey   : `Maturity ${field.fieldName}`,
            customValue : String(form.getFieldValue(field.fieldKey)),
          })),
        ];
      }
    }

    if (productType === ProductTypeEnum.Digital.toString()) {
      if (productSubType === ProductSubTypeEnum.Classic.toString()) {
        newProduct = {
          ...newProduct,
          couponBarrier : {
            stepdown       : 0,
            initialBarrier : values.couponTrigger,
            barrierList    : [],
          },
        };
      } else {
        newProduct = {
          ...newProduct,
          couponBarrier : {
            memoryEffect   : values.couponMemoryEffect,
            stepdown       : 0,
            initialBarrier : 0, // Meaning no barrier
            barrierList    : [],
          },
        };
      }

      // Save protection and maturity fields as custom product fields
      otherCustomFields = productFields.protection
        .filter((field) => form.getFieldValue(field.fieldKey)).map((field) => ({
          customKey   : `Protection ${field.fieldName}`,
          customValue : String(form.getFieldValue(field.fieldKey)),
        }));
    }

    if (productType === ProductTypeEnum.CreditLinkedNote.toString()) {
      otherCustomFields = productFields.creditLinkedNote.filter((field) => form.getFieldValue(field.fieldKey)).map((field) => ({
        customKey   : `CreditLinkedNote ${field.fieldName}`,
        customValue : String(form.getFieldValue(field.fieldKey)),
      }));
    }

    if (productType === ProductTypeEnum.Participation.toString()) {
      otherCustomFields = productFields.maturity.filter((field) => form.getFieldValue(field.fieldKey)).map((field) => ({
        customKey   : `Maturity ${field.fieldName}`,
        customValue : String(form.getFieldValue(field.fieldKey)),
      }));

      if (productSubType === ProductSubTypeEnum.TwoOptionsPayoff.toString()) {
        otherCustomFields = [
          ...otherCustomFields,
          ...productFields.option.filter((field) => form.getFieldValue(field.fieldKey)).map((field) => ({
            customKey   : `Option ${field.fieldName}`,
            customValue : String(form.getFieldValue(field.fieldKey)),
          })),
        ];

        if (hasSecondOptionBarrier) {
          otherCustomFields = [
            ...otherCustomFields,
            ...productFields.option2.filter((field) => form.getFieldValue(field.fieldKey)).map((field) => ({
              customKey   : `Option2 ${field.fieldName}`,
              customValue : String(form.getFieldValue(field.fieldKey)),
            })),
          ];
        }
      }
    }

    if (productType === ProductTypeEnum.Rates.toString()) {
      otherCustomFields = [
        {
          customKey   : 'Coupon Target Coupon',
          customValue : values.targetCoupon.toString(),
        },
        {
          customKey   : 'Coupon First Floating Payment',
          customValue : values.firstFloatingPaymentDate,
        },
      ];
    }

    if (productType !== ProductTypeEnum.Participation.toString() && productType !== ProductTypeEnum.Custom.toString()) {
      newProduct = {
        ...newProduct,
        couponFixed : {
          couponValue   : values.coupon,
          numberPerYear : getFrequencyMultiplier(frequency),
        },
      };
    }

    if (hasCouponFloor) {
      newProduct = {
        ...newProduct,
        couponBarrier : {
          ...newProduct.couponBarrier ?? {
            stepdown : 0,
          },
          couponFloor : values.couponFloor,
        },
      };
    }

    newProduct.fields = [
      ...newProduct.fields,
      ...otherCustomFields,
    ];

    myFetch('POST', '/products', {
      body  : newProduct,
      query : null,
    })
      .then((response) : void => {
        const insertedProduct = response as { productId : number };
        messageApi.open({
          type    : 'success',
          content : 'Product created successfully',
        });
        navigate(`/products/${insertedProduct.productId.toString()}`);
      })
      .catch((e : unknown) : void => {
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : e.message,
          });
        }
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  useCreateBasicProductEvents({
    product,
    setCalendarEvents,
    isEditing,
  });

  useAutoCreateEvent({
    initialValuation      : initialFixingDate,
    issueDate,
    frequency,
    calendarEvents,
    productType,
    nonCallPeriod,
    coupon,
    couponStepdown,
    autocallStepdown,
    couponTrigger,
    autocallTrigger,
    setCalendarEvents,
    totalDurationInMonths : maturityLength,
  });

  return (
    <div className = {'createProductPage'}>
      {contextHolder}

      <MainTitle text = {isEditing ? product?.name : 'Create Product'} showReturnButton = {true} />

      <Form
        name = {'createProduct'}
        className = {'create-product-form'}
        layout = {'vertical'}
        form = {form}
        autoComplete = {'off'}
        requiredMark = {false}
        initialValues = {isEditing
          ? {}
          : {
            productType      : 'Autocall',
            productSubType   : ProductTypeEnum.Custom.toString(),
            strike           : 0,
            autocallStepdown : 0,
            couponStepdown   : 0,
          }}
        onFinish = {onFinish}
      >

        <Row gutter = {24}>
          {/* column 1 : Fields: Isin, Name, Issuer, Product type, backet type; issue, maturity, currency, underlyings, custom fields*/}
          <Col md = {7} sm = {24}>

            <div className = {'form__group form__group_general'}>
              <h4 className = {'section__title section__title__background'}>General</h4>

              <div className = {'form__group_content'}>

                <Form.Item
                  label = {'ISIN Code'}
                  name = {'isin'}
                  rules = {[
                    { required : true },
                    {
                      len     : 12,
                      message : 'ISIN must be 12 characters long',
                    },
                  ]}
                >
                  <Input className = {'product-form-input'} placeholder = {'Enter ISIN...'} />
                </Form.Item>

                <Form.Item
                  name = {'name'}
                  label = {'Product Name'}
                  rules = {[{ required : true }]}
                >
                  <Input className = {'product-form-input'} placeholder = {'Product Name...'} />
                </Form.Item>

                <Form.Item label = {'Issuer'} name = {'issuerId'} rules = {[{ required : true }]}>
                  <Select
                    showSearch
                    placeholder = {'Select Issuer'}
                    loading = {issuers.isLoading}
                    className = {'product-select-input'}
                    options = {issuers.list.map((issuer) => ({
                      // eslint-disable-next-line id-denylist
                      value : issuer.id,
                      label : <span>{issuer.fullName}</span>,
                    }))}
                  />
                </Form.Item>

                <Form.Item label = {'Product Type'} name = {'productType'} rules = {[{ required : true }]}>
                  <Select
                    showSearch
                    placeholder = {'Select Product Type'}
                    className = {'product-select-input'}
                    options = {Object.keys(productTypeMapping).map((type) => ({
                      // eslint-disable-next-line id-denylist
                      value : type,
                      label : <span>{type}</span>,
                    }))}
                  />
                </Form.Item>

                <Form.Item label = {'Product Subtype'} name = {'productSubType'} rules = {[{ required : true }]}>
                  <Select
                    showSearch
                    placeholder = {'Select subType'}
                    className = {'product-select-input'}
                    options = {
                      productType
                        ? productTypeMapping[productType as ProductTypeEnum].map((subType) => ({
                          // eslint-disable-next-line id-denylist
                          value : subType,
                          label : <span>{subType}</span>,
                        }))
                        : []
                    }
                  />
                </Form.Item>

                <Form.Item
                  name = {'basketType'}
                  style = {{ flexBasis : '100%' }}
                  label = {'Basket Type'}
                  rules = {[{ required : true }]}
                >
                  <Select
                    showSearch
                    placeholder = {'Select...'}
                    className = {'product-select-input'}
                    options = {Object.values(BasketTypeEnum).map((type) => ({
                      // eslint-disable-next-line id-denylist
                      value : type,
                      label : <span>{type}</span>,
                    }))}
                  />
                </Form.Item>

                <Flex gap = {8}>
                  <Form.Item
                    label = {'Issue'}
                    style = {{ flexBasis : '100%' }}
                    name = {'issueDate'}
                    rules = {[{ required : true }]}
                    // eslint-disable-next-line id-denylist
                    getValueProps = {(v : string) : ({ value : dayjs.Dayjs | string }) => ({ value : v && dayjs(v) })}
                  >
                    <DatePicker
                      className = {'product-form-input'}
                      style = {{ width : '100%' }}
                      format = {'DD/MM/YYYY'}
                      allowClear = {false}
                    />
                  </Form.Item>

                  <Form.Item
                    label = {'Initial Fixing'}
                    name = {'initialFixingDate'}
                    style = {{ flexBasis : '100%' }}
                    rules = {[{ required : true }]}
                    // eslint-disable-next-line id-denylist
                    getValueProps = {(v : string) : ({ value : dayjs.Dayjs | string }) => ({ value : v && dayjs(v) })}
                  >
                    <DatePicker
                      className = {'product-form-input'}
                      style = {{ width : '100%' }}
                      format = {'DD/MM/YYYY'}
                      allowClear = {false}
                    />
                  </Form.Item>
                </Flex>

                <Flex gap = {8}>
                  <Form.Item
                    label = {'Maturity'}
                    name = {'maturityLength'}
                    rules = {[{ required : true }]}
                    style = {{ flexBasis : '100%' }}
                  >
                    <InputNumber
                      className = {'product-form-input'}
                      style = {{ width : '100%' }}
                      placeholder = {'0'}
                      suffix = {'months'}
                      min = {1}
                    />
                  </Form.Item>

                  <Form.Item
                    label = {'Currency'}
                    style = {{ width : '100%' }}
                    name = {'currencyRefId'}
                    rules = {[{ required : true }]}
                  >
                    <Select
                      showSearch
                      style = {{ width : '100%' }}
                      placeholder = {'Select Currency'}
                      className = {'product-select-input'}
                      options = {dbCurrencyData.map((currency) => ({
                        // eslint-disable-next-line id-denylist
                        value : currency.id,
                        label : <span>{currency.code}</span>,
                      }))}
                    />
                  </Form.Item>
                </Flex>

                {productSubType !== 'Fixed Rate Note' && <AddUnderlyingsForm onAddUnderlying = {onAddUnderlying} />}

                <h4 className = {'section__title-topmargin'}>Additional data</h4>

                {customFields.map((customField, index) => (
                  <Space.Compact key = {index} className = {'customField'}>
                    <Input
                      className = {'product-form-input'}
                      value = {customField.customKey}
                      style = {{ width : '48%' }}
                      placeholder = {'Enter param name...'}
                      onChange = {(e) : void => onChangeInputOptions('customKey', e.target.value, index)}
                    />

                    <Input
                      className = {'product-form-input'}
                      value = {customField.customValue}
                      style = {{ width : '48%' }}
                      placeholder = {'Enter param value...'}
                      onChange = {(e) : void => onChangeInputOptions('customValue', e.target.value, index)}
                    />

                    <Button type = {'dashed'} onClick = {() : void => setCustomFields(customFields.filter((_, i) => i !== index))}>
                      <CloseOutlined />
                    </Button>
                  </Space.Compact>
                ))}

                <Flex className = {'addNewField'} justify = {'flex-end'}>
                  <Button
                    type = {'dashed'}
                    onClick = {addNewCustomField}
                  >
                    Add New Field
                  </Button>
                </Flex>
              </div>
            </div>
          </Col>

          {/* column 2 : Coupon fields, Autocall fields, Redemption fields, termsheet */}
          <Col className = {'createProductPage__formcol'} md = {8} sm = {24}>
            {Object.keys(productFields).map((fieldGroupKey, groupIndex) => {
              if (productFields[fieldGroupKey].length === 0) {
                return null;
              }

              // Hide fields for custom products
              if (productType === ProductTypeEnum.Custom.toString()) {
                return null;
              }

              // Hide Option 2 fields by defaut for participation products
              if (!showSecondOptionsPayOff && fieldGroupKey === 'option2') {
                return null;
              }

              let sectionTitle = toTitleCase(fieldGroupKey);

              if (productSubType === ProductSubTypeEnum.FixedRateNote.toString()
                || productSubType === ProductSubTypeEnum.FloatingRateNote.toString()) {
                sectionTitle = 'Fixed Leg';
              }

              const fieldsGroups = productFields[fieldGroupKey];

              return (
                <div key = {groupIndex} className = {'form__group'}>
                  <h4 className = {'section__title section__title__background'}>{sectionTitle}</h4>

                  <div className = {'form__group_content'}>
                    <Row gutter = {16}>

                      {fieldsGroups.map((field, fieldIndex) => {
                        const disabledFieldsToOption = new Map([
                          ['optionBarrierLevel', !optionAsBarrier],
                          ['optionRebate', !optionAsBarrier],
                          ['optionBarrierType', !optionAsBarrier],
                          ['optionBarrierLevel2', !hasSecondOptionBarrier],
                          ['optionBarrierType2', !hasSecondOptionBarrier],
                          ['optionRebate2', !hasSecondOptionBarrier],
                          ['couponFloor', !hasCouponFloor],
                          ['autocallFloor', !hasAutocallFloor],
                          ['rebateBarrier', !hasMaturityRebate],
                          ['rebateBarrierType', !hasMaturityRebate],
                          ['rebate', !hasMaturityRebate],
                          ['maturityCap', !hasMaturityCap],
                        ]);

                        const hiddenField = disabledFieldsToOption.get(field.fieldKey);

                        if (field.fieldType === 'number') {
                          let minValue : number;
                          if (field.fieldKey === 'autocallTrigger') {
                            minValue = couponTrigger;
                          } else if (field.fieldKey === 'strike') {
                            minValue = barrierLevel;
                          } else {
                            minValue = 0;
                          }
                          return (
                            <Col
                              style = {hiddenField ? { display : 'none' } : { display : 'block' }}
                              key = {fieldIndex}
                              md = {12}
                              sm = {24}
                            >
                              <Form.Item
                                hidden = {hiddenField}
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{ required : field.required }]}
                              >
                                <InputNumber
                                  disabled = {field.disabled}
                                  className = {'product-form-input'}
                                  style = {{ width : '100%' }}
                                  placeholder = {`Enter ${field.fieldName}`}
                                  suffix = {field.suffix}
                                  min = {minValue}
                                />
                              </Form.Item>
                            </Col>
                          );
                        }
                        if (field.fieldType === 'select') {
                          return (
                            <Col
                              style = {hiddenField ? { display : 'none' } : { display : 'block' }}
                              key = {fieldIndex}
                              md = {12}
                              sm = {24}
                            >
                              <Form.Item
                                hidden = {hiddenField}
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{ required : field.required }]}
                              >
                                <Select
                                  showSearch
                                  className = {'product-select-input'}
                                  placeholder = {`Select ${field.fieldName}`}
                                  options = {field.options?.map((option) => ({
                                    // eslint-disable-next-line id-denylist
                                    value : option,
                                    label : option,
                                  })) ?? []}
                                />
                              </Form.Item>
                            </Col>
                          );
                        }

                        if (field.fieldType === 'toggle') {
                          return (
                            <Col
                              key = {fieldIndex}
                              md = {12}
                              sm = {24}
                              style = {{
                                display    : hiddenField ? 'none' : 'flex',
                                alignItems : 'baseline',
                              }}
                            >
                              <Form.Item
                                hidden = {hiddenField}
                                style = {{ margin : 0 }}
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{ required : field.required }]}
                              >
                                <Switch checkedChildren = {'On'} unCheckedChildren = {'Off'} />
                              </Form.Item>
                            </Col>
                          );
                        }

                        if (field.fieldType === 'date') {
                          return (
                            <Col
                              style = {hiddenField ? { display : 'none' } : { display : 'block' }}
                              key = {fieldIndex}
                              md = {12}
                              sm = {24}
                            >
                              <Form.Item
                                hidden = {hiddenField}
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{ required : field.required }]}
                              >
                                <DatePicker style = {{ width : '100%' }} className = {'product-form-input'} placeholder = {field.fieldName} />
                              </Form.Item>
                            </Col>
                          );
                        }

                        return (
                          <Col
                            style = {hiddenField ? { display : 'none' } : { display : 'block' }}
                            key = {fieldIndex}
                            md = {12}
                            sm = {24}
                          >
                            <Form.Item
                              hidden = {hiddenField}
                              label = {field.fieldName}
                              name = {field.fieldKey}
                              rules = {[{ required : field.required }]}
                            >
                              <CurrencyInput className = {'product-form-input'} placeholder = {'0'} />
                            </Form.Item>
                          </Col>
                        );
                      })}
                    </Row>

                    {fieldGroupKey === 'option'
                    && (
                      <Flex justify = {'flex-end'}>
                        <Button
                          style = {{ border : 'none' }}
                          type = {'default'}
                          onClick = {() : void => setShowSecondOptionsPayOff(!showSecondOptionsPayOff)}
                        >
                          {showSecondOptionsPayOff ? 'Remove Option 2' : 'Add Option 2'}
                        </Button>
                      </Flex>
                    )}
                  </div>
                </div>
              );
            })}

            <h4 className = {'section__title section__title-topmargin'}>Termsheet</h4>

            <Upload.Dragger
              name = {'file'}
              accept = {'.pdf'}
              multiple = {false}
              maxCount = {1}
              style = {{
                backgroundColor : 'rgba(255,255,255,.4)',
                width           : '100%',
                marginTop       : '16px',
              }}
              customRequest = {({
                file,
                onError,
                onSuccess,
              } : RcCustomRequestOptions) : void => {
                updloadTermsheet({
                  file,
                  onError,
                  onSuccess,
                });
              }}
            >
              <p className = {'ant-upload-drag-icon'}>
                <FileOutlined />
              </p>

              <Form.Item hidden name = {'termSheet'}>
                <Input type = {'hidden'} />
              </Form.Item>

              <p className = {'ant-upload-text'}>Click or drag file to this area to upload</p>
            </Upload.Dragger>

          </Col>

          {/* column 3 : Calendar and event creation form */}
          <Col className = {'createProductPage__formcol'} md = {9} sm = {24}>

            <div className = {'form__group form__group_calendar'}>
              <h4 className = {'section__title section__title__background'}>Calendar</h4>

              {calendarEvents.length > 0
              && (
                <div className = {'product_timeline_wrapper'}>
                  <CalendarEvents
                    inlineEditEvent = {(updatedEvent) : void => onChangeCalendarEventInfo(updatedEvent)}
                    editionMode = {true}
                    events = {calendarEvents}
                    updateOrDeleteEvent = {onChangeCalendarEvent}
                  />
                </div>
              )}

              <div className = {'form__group_content'}>

                <p className = {'input_event_type__label'}>Event type & Date</p>

                <Space.Compact className = {'customField'}>

                  <Select
                    showSearch
                    placeholder = {'Select Type...'}
                    style = {{ width : '60%' }}
                    className = {'product-select-input'}
                    value = {calendarEventForm.eventType || null}
                    options = {items.map((eventType) => ({
                      // eslint-disable-next-line id-denylist
                      value : eventType,
                      label : <span>{eventType}</span>,
                    }))}
                    dropdownRender = {(menu) : React.ReactElement => (
                      <>
                        {menu}

                        <Divider style = {{ margin : '8px 0' }} />

                        <Space.Compact>
                          <Input
                            className = {'product-form-input'}
                            placeholder = {'Enter custom...'}
                            ref = {inputRef}
                            value = {name}
                            onChange = {onNameChange}
                            onKeyDown = {(e) : void => e.stopPropagation()}
                          />

                          <Button type = {'text'} onClick = {addItem}>
                            Add
                          </Button>
                        </Space.Compact>
                      </>
                    )}
                    onChange = {(type : string) : void => onChangecalendarEventForm('eventType', type)}
                  />

                  <DatePicker
                    style = {{ width : '40%' }}
                    ref = {inputEventDateRef}
                    className = {'product-form-input'}
                    value = {calendarEventForm.valuationDate ? dayjs(calendarEventForm.valuationDate) : null}
                    onChange = {(_, dateString) : void => onChangecalendarEventForm('valuationDate', dateString as string)}
                  />
                </Space.Compact>

                {[EventTypesEnum.AutocallAndCoupon, EventTypesEnum.Coupon].includes(calendarEventForm.eventType as EventTypesEnum) && (
                  <>
                    <p>Potential Flow</p>

                    <Input
                      min = {0}
                      placeholder = {'0'}
                      className = {'product-form-input'}
                      value = {calendarEventForm.outcomeValue}
                      onChange = {(e) : void => onChangecalendarEventForm('outcomeValue', e.target.value)}
                    />
                  </>
                )}

                <Flex justify = {'flex-end'} className = {'addNewEvent_wrapper'}>
                  <Button
                    type = {'default'}
                    onClick = {() : void => (Number.isInteger(currentEventIndex) ? onUpdateCalendarEvent() : onAddCalendarEvent())}
                  >
                    {Number.isInteger(currentEventIndex) ? 'Update' : 'Add'}
                  </Button>
                </Flex>
              </div>
            </div>

            <Flex
              justify = {'flex-end'}
              style = {{
                marginTop : '48px',
                width     : '100%',
              }}
            >
              <Space>
                <Form.Item>
                  <Button size = {'large'} type = {'text'} htmlType = {'reset'}>
                    Reset
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled = {isLoading || isCreating}
                    className = {'submit_product_button'}
                    size = {'large'}
                    type = {'primary'}
                    htmlType = {'submit'}
                  >
                    {isCreating && <Spin size = {'small'} indicator = {<LoadingOutlined spin />} />}

                    {isEditing ? 'Update' : 'Create'}
                  </Button>
                </Form.Item>
              </Space>
            </Flex>

          </Col>
        </Row>
      </Form>
    </div>
  );
};
export { CreateProductPage };
