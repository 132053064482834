import { useEffect } from 'react';

import dayjs from 'dayjs';

import { EventTypesEnum, FrequencyEnum, ProductTypeEnum } from '../../../data/product/productEnum';
import { MONTHS_IN_YEAR } from '../../../utils/time';
import { getFrequencyMultiplier } from '../lifecycleHelpers/lifecycleHelpers';

import type { ProductEvent } from '../../../data/ProductType';

const useAutoCreateEvent = ({
  frequency = FrequencyEnum.Annual.toString(),
  calendarEvents,
  setCalendarEvents,
  initialValuation,
  issueDate,
  nonCallPeriod,
  coupon,
  productType,
  couponStepdown,
  autocallStepdown,
  couponTrigger,
  autocallTrigger,
  totalDurationInMonths,
} : {
  initialValuation      : string,
  issueDate             : string,
  frequency             : string,
  productType           : string,
  nonCallPeriod         : number,
  coupon                : number,
  couponStepdown        : number,
  autocallStepdown      : number,
  couponTrigger         : number,
  autocallTrigger       : number,
  totalDurationInMonths : number,
  calendarEvents        : ProductEvent[],
  setCalendarEvents     : (events : ProductEvent[])=> void,
}) : void => {

  const makeCustomEvent = (dateDifference : number, freq : number) : ProductEvent[] => {
    const customEvents : ProductEvent[] = [];
    for (let i = 1; i <= dateDifference / freq; i++) {
      const newDate = dayjs(issueDate).add(i * freq, 'month')
        .toDate();
      customEvents.push({
        id            : i,
        paymentDate   : dayjs(newDate).format('YYYY-MM-DD'),
        valuationDate : dayjs(newDate).format('YYYY-MM-DD'),
        eventType     : EventTypesEnum.ToSet,
        outcomeValue  : '',
        status        : 'Future',
      });

    }
    if (customEvents.length) {
      const lastEvent = customEvents[customEvents.length - 1];
      customEvents[customEvents.length - 1] = {
        ...lastEvent,

        // subtract one day from the last event for it to appear before the maturity
        paymentDate : dayjs(lastEvent.paymentDate).subtract(1, 'day')
          .format('YYYY-MM-DD'),
        valuationDate : dayjs(lastEvent.valuationDate).subtract(1, 'day')
          .format('YYYY-MM-DD'),
      };
    }
    return customEvents;
  };

  const populateOtherEvents = () : ProductEvent[] => {
    let customEvents : ProductEvent[] = makeCustomEvent(totalDurationInMonths, MONTHS_IN_YEAR / getFrequencyMultiplier(frequency));

    if (customEvents.length > 0) {
      if (productType === ProductTypeEnum.CreditLinkedNote.toString()) {
        customEvents[customEvents.length - 1].eventType = EventTypesEnum.FinalCoupon;
      } else {
        customEvents[customEvents.length - 1].eventType = EventTypesEnum.FinalFixing;
      }
    }

    if (productType === ProductTypeEnum.Autocall.toString()) {
      customEvents = customEvents.map((event, index) => ({
        ...event,
        eventType : index < nonCallPeriod ? EventTypesEnum.Coupon : EventTypesEnum.AutocallAndCoupon,
      }));
    }

    if (!isNaN(couponTrigger)) {
      customEvents = customEvents.map((event, index) => ({
        ...event,
        couponTrigger : isNaN(couponStepdown) ? couponTrigger : couponTrigger - (couponStepdown * index),
      }));
    }

    if (productType === ProductTypeEnum.Autocall.toString() && !isNaN(autocallTrigger)) {
      customEvents = customEvents.map((event, index) => ({
        ...event,
        autocallTrigger : isNaN(autocallStepdown) ? autocallTrigger : autocallTrigger - (autocallStepdown * index),
      }));
    }

    if (productType === ProductTypeEnum.Digital.toString() || productType === ProductTypeEnum.CreditLinkedNote.toString()
      || productType === ProductTypeEnum.Rates.toString()) {
      customEvents = customEvents.map((event) => (
        {
          ...event,
          eventType : EventTypesEnum.Coupon,
        }
      ));
    }

    return customEvents;
  };

  const addOrUpdateEvent = (
    events : ProductEvent[],
    newEvent : ProductEvent,
    eventType : string
  ) : ProductEvent[] => {
    const eventIndex = events.findIndex((event) => event.eventType === eventType);
    // eslint-disable-next-line no-negated-condition
    if (eventIndex !== -1) {
      events[eventIndex] = newEvent;
    } else {
      events.push(newEvent);
    }
    return events;
  };

  useEffect(() => {
    let updatedEvents = [...calendarEvents];

    // Add or update fixed events
    if (totalDurationInMonths && issueDate) {
      const maturityDateAsDate = dayjs(issueDate).add(totalDurationInMonths, 'month')
        .format('YYYY-MM-DD');
      const maturityEvent : ProductEvent = {
        id            : updatedEvents.length + 1,
        status        : 'Future',
        eventType     : EventTypesEnum.MaturityDate,
        paymentDate   : maturityDateAsDate,
        valuationDate : maturityDateAsDate,
      };
      updatedEvents = addOrUpdateEvent(updatedEvents, maturityEvent, EventTypesEnum.MaturityDate);
    }

    if (issueDate) {
      const issueDateEvent : ProductEvent = {
        id            : updatedEvents.length + 1,
        status        : 'Future',
        eventType     : EventTypesEnum.IssueDate,
        paymentDate   : dayjs(issueDate).format('YYYY-MM-DD'),
        valuationDate : dayjs(issueDate).format('YYYY-MM-DD'),
      };
      updatedEvents = addOrUpdateEvent(updatedEvents, issueDateEvent, EventTypesEnum.IssueDate);
    }

    if (initialValuation) {
      const initialFixingDateEvent : ProductEvent = {
        id            : updatedEvents.length + 1,
        status        : 'Future',
        eventType     : EventTypesEnum.InitialFixing,
        paymentDate   : dayjs(initialValuation).format('YYYY-MM-DD'),
        valuationDate : dayjs(initialValuation).format('YYYY-MM-DD'),
      };
      updatedEvents = addOrUpdateEvent(
        updatedEvents,
        initialFixingDateEvent,
        EventTypesEnum.InitialFixing
      );
    }

    const basicEventTypes = [EventTypesEnum.MaturityDate, EventTypesEnum.IssueDate, EventTypesEnum.InitialFixing];

    // Add custom frequency-based events
    if (totalDurationInMonths && frequency && issueDate) {
      const customEvents = populateOtherEvents();
      updatedEvents = [
        ...updatedEvents.filter((event) => basicEventTypes.includes(event.eventType as EventTypesEnum)),
        ...customEvents,
      ];
    }

    if (coupon) {
      updatedEvents = updatedEvents.map((event) => ({
        ...event,
        outcomeValue : (coupon / getFrequencyMultiplier(frequency)).toFixed(2),
      }));
    }

    // Handle productType-specific filtering
    if (productType === ProductTypeEnum.Custom.toString()) {
      updatedEvents = updatedEvents.filter(
        (event) => basicEventTypes.includes(event.eventType as EventTypesEnum)
      );
    }

    setCalendarEvents(updatedEvents.sort((left, right) => new Date(left.valuationDate).getTime() - new Date(right.valuationDate).getTime()));
  }, [
    totalDurationInMonths,
    frequency,
    initialValuation,
    productType,
    issueDate,
    nonCallPeriod,
    coupon,
    couponStepdown,
    autocallStepdown,
    couponTrigger,
    autocallTrigger,
  ]);
};

export { useAutoCreateEvent };
