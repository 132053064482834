import React, { useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { BankOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch
} from 'antd';
import type { FormProps } from 'antd';

import dayjs from 'dayjs';

import { CurrencyInput } from '../../../Shared/CurrencyInput/CurrencyInput';
import { EditTradePageRoute } from '../../../data/Routes';
import { CurrencyLocaleMap } from '../../../data/currency';
import { tradeData } from '../TradePage/data';

import { CreateIsinModal } from './CreateIsinModal';
import { EditCustodianModal } from './EditCustodianModal';
import {
  businessProviders,
  clientsList, isinList, issuerList, owners
} from './data';

import type { TradeFormFieldType } from '../../../data/TradesTypes';
import type { CurrencyNameType } from '../../../data/currency';

import './CreateTradePage.scss';

export const CreateTradePage = () : React.JSX.Element => {
  const [form] = Form.useForm<TradeFormFieldType>();
  const [selectedCurrency, setselectedCurrency] = useState<CurrencyNameType>('EUR');
  const [showCreateIsinModal, setShowCreateIsinModal] = useState(false);
  const [showEditCustodianModal, setShowEditCustodianModal] = useState(false);
  const [inputOptions, setInputOptions] = useState({
    toBeDetermined         : false,
    commissionOption       : false,
    businessProviderOption : false,
    bridgeOption           : false,
  });

  const onChangeInputOptions = (checked : boolean, name : string) => {
    setInputOptions({
      ...inputOptions,
      [name] : checked,
    });
  };

  const { tradeId } = useParams<{ tradeId : string }>();
  const isEditing = Boolean(useMatch({ path : EditTradePageRoute.path }));

  useEffect(() => {
    if (!isEditing) {
      return;
    }

    // Prefill the form with default values when editing
    form.setFieldsValue({
      owner                 : tradeData.owner,
      issuer                : tradeData.issuer,
      currency              : tradeData.currency,
      isinCode              : tradeData.isin,
      productName           : tradeData.productName,
      denomination          : tradeData.denomination,
      notionalAmount        : tradeData.nominal,
      tradeDate             : dayjs(tradeData.tradeDate).format(),
      issueDate             : dayjs(tradeData.issueDate).format(),
      representative        : tradeData.representative,
      reofferPrice          : tradeData.reofferPrice,
      cashAmount            : tradeData.cashAmount,
      clientName            : tradeData.clientName,
      clientPrice           : tradeData.clientPrice,
      clientAmount          : tradeData.clientCashAmount,
      commissionPercent     : tradeData.commission,
      totalCommissionAmount : tradeData.totalCommission,
      clientCommission      : tradeData.clientCommission,
      aydoCommission        : tradeData.aydoCommision,
    });
  }, []);

  const onFinish : FormProps<TradeFormFieldType>['onFinish'] = (values) => {
    // TODO Submit the form
    console.log(values);
  };

  const {
    bridgeOption, businessProviderOption, commissionOption, toBeDetermined,
  } = inputOptions;

  return (
    <>
      <CreateIsinModal isModalOpen = {showCreateIsinModal} close = {() => setShowCreateIsinModal(false)} />

      <EditCustodianModal isModalOpen = {showEditCustodianModal} close = {() => setShowEditCustodianModal(false)} />

      <div className = {'createTradePage'}>
        <Form
          name = {'createTrade'}
          layout = {'vertical'}
          form = {form}
          initialValues = {{ remember : true }}
          autoComplete = {'off'}
          requiredMark = {false}
          onFinish = {onFinish}
        >
          <Row gutter = {90}>
            <Col className = {'createTradePage__header'} span = {16}>
              <h2>Create Trade</h2>
            </Col>

            <Col span = {8}>
              <Form.Item
                label = {'Select owner'}
                layout = {'horizontal'}
                name = {'owner'}
                rules = {[{ required : true }]}
              >
                <Select
                  showSearch
                  placeholder = {'Select owner'}
                  style = {{ width : '100%' }}
                  filterOption = {(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
                  options = {owners.map((owner) => ({
                    value : owner,
                    label : <span>{owner}</span>,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter = {90}>
            <Col className = {'createTradePage__formcol'} md = {8} sm = {24}>
              <h4 className = {'section__title'}>Main Features</h4>

              <Form.Item label = {'Isin Code'} name = {'isinCode'} rules = {[{ required : !toBeDetermined }]}>
                <Select
                  showSearch
                  placeholder = {'Select ISIN'}
                  options = {isinList.map((value) => ({
                    value,
                    label : <span>{value}</span>,
                  }))}
                />
              </Form.Item>

              <Space className = {'createTradePage__tbd'}>
                <Checkbox onChange = {(e) : void => onChangeInputOptions(e.target.checked, 'toBeDetermined')}>TBD</Checkbox>

                {toBeDetermined && (
                  <Space>
                    <p className = {'createTradePage__tbd__text'}>To be determined</p>

                    <Button
                      className = {'createTradePage__tbd__button'}
                      type = {'link'}
                      onClick = {() : void => setShowCreateIsinModal(true)}
                    >
                      Create ISIN
                    </Button>
                  </Space>
                )}
              </Space>

              {toBeDetermined && (
                <>
                  <Form.Item label = {'Product Name'} name = {'productName'} rules = {[{ required : true }]}>
                    <Input placeholder = {'Product Name'} />
                  </Form.Item>

                  <Form.Item label = {'Denomination'} rules = {[{ required : true }]} name = {'denomination'}>
                    <CurrencyInput currency = {selectedCurrency} />
                  </Form.Item>

                  <Form.Item label = {'Issuer'} name = {'issuer'} rules = {[{ required : true }]}>
                    <Select
                      showSearch
                      placeholder = {'Select...'}
                      options = {issuerList.map((te) => ({
                        value : te,
                        label : <span>{te}</span>,
                      }))}
                    />
                  </Form.Item>
                </>
              )}

              <Form.Item label = {'Currency'} name = {'currency'} rules = {[{ required : true }]}>
                <Select
                  showSearch
                  placeholder = {'Select currency'}
                  options = {Object.keys(CurrencyLocaleMap).map((curr) => ({
                    value : curr,
                    label : <span>{curr}</span>,
                  }))}
                  onChange = {(newVal) : void => setselectedCurrency(newVal as CurrencyNameType)}
                />
              </Form.Item>

              <Form.Item label = {'Notional Amount'} name = {'notionalAmount'} rules = {[{ required : true }]}>
                <CurrencyInput currency = {selectedCurrency} />
              </Form.Item>

              <h4 className = {'section__title section__title-topmargin'}>Date</h4>

              <Space className = {'select_date'}>
                <Form.Item
                  label = {'Trade Date'}
                  name = {'tradeDate'}
                  rules = {[{ required : true }]}
                  // eslint-disable-next-line id-denylist
                  getValueProps = {(date : string) : { value : dayjs.Dayjs } => ({ value : dayjs(date) })}
                >
                  <DatePicker format = {'DD/MM/YYYY'} placeholder = {'DD/MM/YYYY'} allowClear = {false} />
                </Form.Item>

                <Form.Item
                  label = {'Issue Date'}
                  name = {'issueDate'}
                  rules = {[{ required : true }]}
                  // eslint-disable-next-line id-denylist
                  getValueProps = {(date : string) : { value : dayjs.Dayjs } => ({ value : dayjs(date) })}
                >
                  <DatePicker format = {'DD/MM/YYYY'} placeholder = {'DD/MM/YYYY'} />
                </Form.Item>
              </Space>
            </Col>

            <Col md = {8} sm = {24}>
              <Flex vertical>
                <h4 className = {'section__title'}>AYDO {'<>'} Trading Entity</h4>

                <Form.Item label = {'Representative'} name = {'representative'} rules = {[{ required : true }]}>
                  <Select
                    showSearch
                    placeholder = {'Select...'}
                    options = {issuerList.map((value) => ({
                      value,
                      label : <span>{value}</span>,
                    }))}
                  />
                </Form.Item>

                <Space>
                  <Form.Item label = {'Reoffer Price'} name = {'reofferPrice'} rules = {[{ required : true }]}>
                    <InputNumber
                      placeholder = {'0'}
                      style = {{ width : '100%' }}
                      min = {0}
                      max = {100}
                      suffix = {'%'}
                    />
                  </Form.Item>

                  <Form.Item label = {'Cash Amount'} name = {'cashAmount'} rules = {[{ required : true }]}>
                    <CurrencyInput currency = {selectedCurrency} />
                  </Form.Item>
                </Space>

                <h4 className = {'section__title section__title-topmargin'}>AYDO {'<>'} Client</h4>

                <Form.Item label = {'Client Name'} name = {'clientName'} rules = {[{ required : true }]}>
                  <Select
                    showSearch
                    placeholder = {'Select...'}
                    options = {clientsList.map((value) => ({
                      value,
                      label : <span>{value}</span>,
                    }))}
                  />
                </Form.Item>

                <Space>
                  <Form.Item label = {'Client Price'} name = {'clientPrice'} rules = {[{ required : true }]}>
                    <InputNumber
                      placeholder = {'0'}
                      style = {{ width : '100%' }}
                      min = {0}
                      max = {100}
                      suffix = {'%'}
                    />
                  </Form.Item>

                  <Form.Item label = {'Client Amount'} name = {'clientAmount'} rules = {[{ required : true }]}>
                    <CurrencyInput currency = {selectedCurrency} />
                  </Form.Item>
                </Space>

                <Space className = {'section__check section__check_topmargin'}>
                  <h4 className = {'section__check__title'}>Commission</h4>

                  <Switch
                    checkedChildren = {'ON'}
                    unCheckedChildren = {'OFF'}
                    checked = {commissionOption}
                    onChange = {(checked) => onChangeInputOptions(checked, 'commissionOption')}
                  />
                </Space>

                {commissionOption && (
                  <Form.Item label = {'Commission'} name = {'commissionPercent'} rules = {[{ required : true }]}>
                    <InputNumber
                      placeholder = {'0'}
                      style = {{ width : '100%' }}
                      min = {0}
                      max = {100}
                      suffix = {'%'}
                    />
                  </Form.Item>
                )}
              </Flex>
            </Col>

            <Col md = {8} sm = {24}>
              <Flex vertical>
                <Space className = {'section__check'}>
                  <h4 className = {'section__check__title'}>Business Provider</h4>

                  <Switch
                    checkedChildren = {'ON'}
                    unCheckedChildren = {'OFF'}
                    checked = {businessProviderOption}
                    onChange = {(checked) => onChangeInputOptions(checked, 'businessProviderOption')}
                  />
                </Space>

                {businessProviderOption && (
                  <>
                    <Form.Item label = {'Name'} name = {'businessProviderName'} rules = {[{ required : true }]}>
                      <Select
                        showSearch
                        placeholder = {'Select...'}
                        options = {businessProviders.map((value) => ({
                          value,
                          label : <span>{value}</span>,
                        }))}
                      />
                    </Form.Item>

                    <Space>
                      <Form.Item label = {'Fees'} name = {'businessProviderFees'} rules = {[{ required : true }]}>
                        <InputNumber
                          placeholder = {'0'}
                          style = {{ width : '100%' }}
                          min = {0}
                          max = {100}
                          suffix = {'%'}
                        />
                      </Form.Item>

                      <Form.Item label = {'Fees Amount'} name = {'businessProviderAmount'} rules = {[{ required : true }]}>
                        <CurrencyInput currency = {selectedCurrency} />
                      </Form.Item>
                    </Space>
                  </>
                )}

                <Space className = {'section__check section__check_topmargin'}>
                  <h4 className = {'section__check__title'}>Bridge</h4>

                  <Switch
                    checkedChildren = {'ON'}
                    unCheckedChildren = {'OFF'}
                    checked = {bridgeOption}
                    onChange = {(checked) => onChangeInputOptions(checked, 'bridgeOption')}
                  />
                </Space>

                {bridgeOption && (
                  <>
                    <p className = {'section_info'}>Total Commission = Total Upfront - Bridge Fee</p>

                    <Form.Item label = {'Name'} name = {'bridgeName'} rules = {[{ required : true }]}>
                      <Select
                        showSearch
                        placeholder = {'Select...'}
                        options = {businessProviders.map((value) => ({
                          value,
                          label : <span>{value}</span>,
                        }))}
                      />
                    </Form.Item>

                    <Space>
                      <Form.Item label = {'Fees'} name = {'bridgeFees'} rules = {[{ required : true }]}>
                        <InputNumber
                          placeholder = {'0'}
                          style = {{ width : '100%' }}
                          min = {0}
                          max = {100}
                          suffix = {'%'}
                        />
                      </Form.Item>

                      <Form.Item label = {'Fees Amount'} name = {'bridgeAmount'} rules = {[{ required : true }]}>
                        <CurrencyInput currency = {selectedCurrency} />
                      </Form.Item>
                    </Space>
                  </>
                )}

                <div className = {'custodians__infos'}>
                  <Space>
                    <BankOutlined />

                    <p>Custodians:</p>
                  </Space>

                  <Button type = {'link'} onClick = {() => setShowEditCustodianModal(true)}>
                    Edit
                  </Button>
                </div>

                <h4 className = {'section__title section__title-topmargin'}>Commission Details</h4>

                {businessProviderOption
                  ? (
                    <>
                      <Form.Item label = {'Total Commission:'} name = {'totalCommissionAmount'}>
                        <CurrencyInput currency = {selectedCurrency} />
                      </Form.Item>

                      <Form.Item label = {'- Client Commission:'} name = {'clientCommission'}>
                        <CurrencyInput currency = {selectedCurrency} />
                      </Form.Item>

                      <Form.Item label = {'- Business Provider Fee:'} name = {'businessProviderCommission'}>
                        <CurrencyInput currency = {selectedCurrency} />
                      </Form.Item>

                      <Form.Item label = {'= AYDO Commission'} name = {'aydoCommission'}>
                        <CurrencyInput currency = {selectedCurrency} />
                      </Form.Item>
                    </>
                  )
                  : (
                    <Form.Item label = {'AYDO Commission'} name = {'aydoCommission'}>
                      <CurrencyInput currency = {selectedCurrency} />
                    </Form.Item>
                  )}
              </Flex>
            </Col>
          </Row>

          <Flex justify = {'flex-end'}>
            <Space>
              <Form.Item>
                <Button className = {'submit_button'} type = {'link'} htmlType = {'reset'}>
                  Reset
                </Button>
              </Form.Item>

              <Form.Item>
                <Button className = {'submit_button'} type = {'primary'} htmlType = {'submit'}>
                  Book
                </Button>
              </Form.Item>
            </Space>
          </Flex>
        </Form>
      </div>
    </>
  );
};
