import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CaretDownOutlined, CaretUpOutlined, EyeOutlined, InfoCircleOutlined, LoadingOutlined
} from '@ant-design/icons';
import {
  Button, Col, Empty, Flex, Row, Spin, Table, Tag,
  Tooltip
} from 'antd';
import type { TableColumnsType } from 'antd';

import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { CalendarEvents } from '../../../Shared/CalendarEvents/CalendarEvents';
import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { MainTitle } from '../../../Shared/MainTitle/MainTitle';
import billIcon from '../../../assets/billIcon.svg';
import userIcon from '../../../assets/user.png';
import { getSingleProduct } from '../../../store/productsStore/productApi';
import {
  expertGreen, goldenYellow, whiteColor
} from '../../../styles/colors';
import { parseCurrency } from '../../../utils/currencyparser';
import { isNumberString } from '../../../utils/stringUtils';
import { formatMonthsToYearsMonths } from '../../../utils/time';
import { ProductValorisation } from '../Shared/ProductValuation/ProductValuation';
import { useCreateBasicProductEvents } from '../lifecycleHooks/useCreateInitProductEvents';

import type { ProductEvent } from '../../../data/ProductType';
import type { TradeDataType, TradeDetailsType } from '../../../data/TradesTypes';
import type { CurrencyNameType } from '../../../data/currency';
import type { AppDispatch, RootStateType } from '../../../store/store';

import './ProductPage.scss';

dayjs.extend(isSameOrBefore);

const ProductPage = () : React.ReactNode => {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId : string }>();
  const [calendarEvents, setCalendarEvents] = useState<ProductEvent[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const productItem = useSelector((state : RootStateType) => state.productsReducer.product);
  const {
    isLoading, item: product,
  } = productItem;

  useEffect(() => {
    if (!productId) {
      return;
    }
    void dispatch(getSingleProduct({ productId }));
  }, []);

  useCreateBasicProductEvents({
    product,
    setCalendarEvents,
    isEditing : true,
  });

  const expandedRowRender = (custodians : TradeDetailsType[], currency : CurrencyNameType) : React.ReactNode => {
    const innerColumns : TableColumnsType<TradeDetailsType> = [
      {
        title     : 'Custodian Name',
        dataIndex : 'name',
        key       : 'name',
      },
      {
        title     : 'Amount',
        dataIndex : 'amount',
        key       : 'amount',
        render    : (val) : string => parseCurrency(val as number, currency),
      },
    ];

    return (
      custodians.length === 0
        ? (
          <Flex justify = {'center'}>
            <p>No custodians</p>
          </Flex>
        )
        : (
          <Table
            columns = {innerColumns}
            className = {'expanded__table'}
            id = {'expanded__table__inner__table--2'}
            dataSource = {custodians}
            pagination = {false}
            rowClassName = {(_, index) : string => (index % 2 === 0 ? 'inner-table-row-even' : 'inner-table-row-odd')}
          />
        )
    );
  };

  if (isLoading) {
    return (
      <Flex
        align = {'center'}
        justify = {'center'}
        gap = {'middle'}
        style = {{ height : '80vh' }}
      >
        <Spin indicator = {(
          <LoadingOutlined
            spin
            style = {{
              fontSize : 48,
              color    : whiteColor,
            }}
          />
        )}
        />
      </Flex>
    );
  }

  const columns : TableColumnsType<TradeDataType> = [
    {
      title     : 'Client',
      dataIndex : 'clientName',
      width     : '33%',
    },
    {
      title     : 'Representative',
      dataIndex : 'representative',
      width     : '33%',
    },
    {
      title     : 'Outstanding Amount',
      dataIndex : 'outstandingAmount',
      render    : (_, record) : React.ReactNode => <>{parseCurrency(record.custodians.reduce((acc, obj) => acc + obj.amount, 0), record.currency)}</>,
      align     : 'right',
    },
    {
      title     : '',
      dataIndex : '',
      width     : 50,
      render    : (_, record) : React.ReactNode => (
        <Button
          icon = {<EyeOutlined />}
          href = {`/trades/${record.id.toString()}`}
          type = {'text'}
          onClick = {(e) : void => e.stopPropagation()}
        />
      )
      ,
    },
  ];

  if (!product) {
    return <Empty description = {'Product not found'} image = {Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const couponPerAnnumValue = (product.couponFixed?.couponValue ?? 0).toFixed(2);
  const couponPerPeriod = ((product.couponFixed?.couponValue ?? 0) / (product.couponFixed?.numberPerYear ?? 1)).toFixed(2);

  return (
    <div className = {'product__container'}>
      <Row
        gutter = {[16, 16]}
        align = {'middle'}
        className = {'product__header'}
        justify = {'space-between'}
      >
        <Col lg = {20} md = {12} xs = {24}>
          <MainTitle showReturnButton text = {product.name} />
        </Col>

        <Col lg = {4} md = {12} xs = {24}>
          <Flex justify = {'flex-end'}>
            <ProductValorisation
              valorisationValue = {product.valorisation}
              prefixText = {'Valorisation: '}
              style = {{
                padding      : '12px 18px',
                fontWeight   : 500,
                border       : 'none',
                marginBottom : '12px',
              }}
            />
          </Flex>

          <Flex justify = {'end'} align = {'center'}>
            <span className = {'product__status_text'}>Status: </span>

            <Tag className = {'product__status'} color = {product.status === 'Active' ? 'green' : 'red'}>
              {product.status}
            </Tag>

            <Button
              type = {'primary'}
              size = {'middle'}
              style = {{
                marginLeft : '16px',
                background : whiteColor,
                color      : expertGreen,
              }}
              onClick = {() : void => navigate(`/products/${product.id.toString()}/edit`)}
            >Edit
            </Button>
          </Flex>
        </Col>
      </Row>

      <Row gutter = {[16, 16]}>
        <Col lg = {6} md = {12} xs = {24}>
          <div className = {'content__box content__box__left'}>
            <Flex className = {'outstanding-amount-box'}>
              <img src = {billIcon} />

              <p>{parseCurrency(product.trades.reduce((acc, obj) => acc + obj.outstandingAmount, 0), product.currency)}</p>

              <span>Outstanding Amount</span>
            </Flex>

            <div>

              <h4>General</h4>

              <div className = {'content__box__left_section'}>
                <Flex wrap = {false} className = {'info__item info__item__odd'} align = {'center'}>
                  <label>
                    ISIN
                    <Tooltip
                      color = {expertGreen}
                      placement = {'topRight'}
                      overlayInnerStyle = {{ color : whiteColor }}
                      title = {'International Securities Identification Number'}
                    >
                      <InfoCircleOutlined style = {{
                        marginLeft : '3px',
                        color      : goldenYellow,
                      }}
                      />
                    </Tooltip>
                  </label>

                  <p>{product.isin}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__even'}>
                  <label>
                    Issuer
                    <Tooltip
                      color = {expertGreen}
                      placement = {'topRight'}
                      overlayInnerStyle = {{ color : whiteColor }}
                      title = {'Company that issues an garanteed the product'}
                    >
                      <InfoCircleOutlined style = {{
                        marginLeft : '3px',
                        color      : goldenYellow,
                      }}
                      />
                    </Tooltip>
                  </label>

                  <p>{product.issuerShortName}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Product Type
                  </label>

                  <p>{product.productType}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Maturity
                  </label>

                  <p>
                    {formatMonthsToYearsMonths(product.totalDuration)}
                  </p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Currency
                  </label>

                  <p>{product.currency}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label style = {{ flexBasis : '150px' }}>
                    Coupon
                    <Tooltip
                      color = {expertGreen}
                      overlayInnerStyle = {{ color : whiteColor }}
                      placement = {'topRight'}
                      title = {'Value Per Annum'}
                    >
                      <InfoCircleOutlined style = {{
                        marginLeft : '3px',
                        color      : goldenYellow,
                      }}
                      />
                    </Tooltip>
                  </label>

                  <p>{couponPerAnnumValue}% p.a</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Initial Fixing Date
                  </label>

                  <p>{dayjs(product.initialValuationDate).format('DD/MM/YYYY')}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Maturity Date
                  </label>

                  <p>{dayjs(product.maturityDate).format('DD/MM/YYYY')}</p>
                </Flex>
              </div>

              {product.fields && (
                <>
                  <h4 className = {'section__title_margin'}>Others</h4>

                  <div className = {'content__box__left_section'}>
                    {product.fields.map((field, i) => field.customValue
                      && (
                        <Flex key = {i} wrap = {false} className = {`info__item ${i % 2 ? 'info__item__odd' : 'info__item__even'}`}>
                          <label>{field.customKey}</label>

                          <p>{isNumberString(field.customValue) ? `${field.customValue}%` : field.customValue}</p>
                        </Flex>
                      ))}
                  </div>
                </>
              )}

              <div className = {'action_section'}>
                <Flex align = {'center'}>
                  <img className = {'userIcon'} src = {userIcon} />

                  <p className = {'desc'}>Ready to invest? Need more information?</p>
                </Flex>

                <Button className = {'connect_button'} type = {'primary'}>Contact us</Button>
              </div>
            </div>

          </div>
        </Col>

        <Col
          className = {'grid__column'}
          lg = {11}
          md = {12}
          xs = {24}
        >
          <div className = {'content__box__nopadding'} style = {{ marginBottom : '16px' }}>
            <CustomTable<TradeDataType>
              columns = {columns}
              dataList = {product.trades}
              rowKey = {(record) : string => record.id.toString()}
              pagination = {false}
              bordered = {false}
              headerHidden = {true}
              expandable = {{
                expandedRowRender      : (record) : React.ReactNode => expandedRowRender(record.custodians, record.currency),
                defaultExpandedRowKeys : ['0'],
                expandRowByClick       : true,
                expandIcon             : ({
                  expanded, onExpand, record,
                }) : React.ReactNode => (expanded
                  ? <Button type = {'text'} icon = {<CaretUpOutlined />} onClick = {(e) : void => onExpand(record, e)} />
                  : <Button type = {'text'} icon = {<CaretDownOutlined />} onClick = {(e) : void => onExpand(record, e)} />),
              }}
            />
          </div>

          <div className = {'content__box content__box__nopadding'}>
            <div className = {'pdf__viewer'}>
              <h4 className = {'box__section__title'}>Termsheet</h4>

              <iframe src = {product.termSheet} width = {'100%'} height = {'800px'} />
            </div>
          </div>
        </Col>

        <Col lg = {7} md = {12} xs = {24}>
          <div className = {'content__box content__box__calendar'}>
            <h4 className = {'box__section__title'}>Calendar</h4>

            <CalendarEvents events = {calendarEvents.map((event) => ({
              ...event,
              outcomeValue : couponPerPeriod,
            }))}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { ProductPage };
