import { useEffect, useState } from 'react';

import { Timeline } from 'antd';

import dayjs from 'dayjs';

import {
  goldenYellow, greyColor, whiteColor
} from '../../styles/colors';
import { EditableText } from '../EditableText/EditableText';

import { CalendarEventCard } from './CalendarEventCard';

import type { ProductEvent } from '../../data/ProductType';

import './CalendarEvents.scss';

type CalendarEventsPropsType = {
  events                : ProductEvent[],
  editionMode ?         : boolean,
  updateOrDeleteEvent ? : (eventIndex : number, action : 'edit' | 'remove')=> void,
  inlineEditEvent ?     : (updatedProductEvent : ProductEvent)=> void,
};

const CalendarEvents = ({
  events, editionMode, updateOrDeleteEvent, inlineEditEvent,
} : CalendarEventsPropsType) : React.ReactNode => {
  const [sortedEvents, setSortedEvents] = useState<ProductEvent[]>(events);

  useEffect(() => {
    if (events.length) {
      setSortedEvents(events.sort((left, right) => new Date(left.valuationDate).getTime() - new Date(right.valuationDate).getTime()));
    }
  }, [events]);

  const getNextEvent = () : ProductEvent | null => {
    const currentDate = dayjs();
    const filteredEvents = events.filter((event) => dayjs(event.valuationDate).isAfter(currentDate));
    return filteredEvents.length > 0 ? filteredEvents[0] : null;
  };

  const onChangeEventInfo = (updatedProductEvent : ProductEvent) : void => {
    if (inlineEditEvent) {
      inlineEditEvent(updatedProductEvent);
    }
  };

  const getStepColor = ({
    isBeforeNextEvent, isTodayEvent,
  } : { isBeforeNextEvent : boolean,
    isTodayEvent          : boolean, }) : string => {
    if (isTodayEvent) {
      return goldenYellow;
    }
    if (isBeforeNextEvent) {
      return greyColor;
    }
    return whiteColor;
  };

  const nextEvent = getNextEvent();
  const today = dayjs().format('YYYY-MM-DD');

  return (
    <Timeline
      className = {'product__timeline'}
      mode = {'alternate'}
      items = {
        sortedEvents.map((event, index) => {
          const isBeforeNextEvent = nextEvent ? dayjs(event.valuationDate).isBefore(dayjs(nextEvent.valuationDate)) : false;
          const isNextEvent = nextEvent?.valuationDate === event.valuationDate && nextEvent.eventType === event.eventType;
          const isTodayEvent = today === dayjs(event.valuationDate).format('YYYY-MM-DD');
          return {
            label : !isTodayEvent
              && (
                <EditableText
                  editable = {Boolean(editionMode)}
                  defaultValue = {dayjs(event.valuationDate).format('YYYY-MM-DD')}
                  valueType = {'date'}
                  changeCallback = {(changedValue) : void => onChangeEventInfo({
                    ...event,
                    valuationDate : changedValue,
                    paymentDate   : changedValue,
                  })}
                />
              ),
            children :
            (<CalendarEventCard
              editionMode = {editionMode}
              event = {event}
              eventIndex = {index}
              className = {'single_event'}
              isPastEvent = {isBeforeNextEvent}
              isToday = {isTodayEvent}
              isNextEvent = {isNextEvent}
              addOrDeleteEvent = {updateOrDeleteEvent}
              onChangeEventInfo = {onChangeEventInfo}
            />),
            position  : isTodayEvent ? 'right' : 'left',
            className : isTodayEvent ? 'today-event' : '',
            color     : getStepColor({
              isBeforeNextEvent,
              isTodayEvent,
            }),
          };
        })
      }
    />
  );
};

export { CalendarEvents };
