// TODO: This file is temporal and will be removed when the data is fetched from the back-end
export const dbCurrencyData = [
  {
    id     : 35,
    code   : 'EUR',
    symbol : '€',
    name   : 'Euro',
  },
  {
    id     : 2,
    code   : 'USD',
    symbol : '$',
    name   : 'United States dollar',
  },
  {
    id     : 4,
    code   : 'CHF',
    symbol : 'CHF',
    name   : 'Swiss franc',
  },
  {
    id     : 5,
    code   : 'GBP',
    symbol : '£',
    name   : 'Pound sterling',
  },
  {
    id     : 3,
    code   : 'AUD',
    symbol : 'A$',
    name   : 'Australian dollar',
  },
  {
    id     : 8,
    code   : 'BRL',
    symbol : 'R$',
    name   : 'Brazilian real',
  },
  {
    id     : 6,
    code   : 'CAD',
    symbol : 'CA$',
    name   : 'Canadian dollar',
  },
  {
    id     : 10,
    code   : 'INR',
    symbol : '₹',
    name   : 'Indian rupee',
  },
  {
    id     : 9,
    code   : 'ILS',
    symbol : '₪',
    name   : 'Israeli new shekel',
  },
  {
    id     : 7,
    code   : 'JPY',
    symbol : '¥',
    name   : 'Japanese yen',
  },
];
