// TODO: This file is temporal and will be removed when the data is fetched from the back-end

import type { TradeInfo } from '../../../data/TradesTypes';

export const tradeStatuses : { status : string,
  current                             : boolean, }[] = [
  {
    status  : 'Booked',
    current : false,
  },
  {
    status  : 'Order received',
    current : true,
  },
  {
    status  : 'Invoice sent',
    current : false,
  },
  {
    status  : 'Commission to be paid',
    current : false,
  },
  {
    status  : 'Closed',
    current : false,
  },
];

export const tradeData : TradeInfo = {
  id               : '1',
  isin             : 'XS2842347580',
  productName      : '2Y Bonus Certificate Plus Worst-of on Burberry Group PLC, Kering SA and LVMH Moet Hennessy Louis Vuitton SE in USD Quanto',
  denomination     : 100,
  issuer           : 'BNP Paribas Issuance B.V.',
  owner            : 'John Doe',
  currency         : 'EUR',
  nominal          : 1320000,
  tradeDate        : '2024-09-20',
  issueDate        : '2025-10-19',
  tradingEntity    : 'BNP Paribas Financial Markets',
  representative   : 'John Doe',
  reofferPrice     : 89.12,
  cashAmount       : 2898872,
  clientName       : 'John Doe',
  clientPrice      : 88,
  clientCashAmount : 239821,
  commission       : 12,
  totalCommission  : 29873,
  clientCommission : 19873,
  aydoCommision    : 10000,
  status           : '',
  custodians       : [],
};
