import { useEffect } from 'react';

import dayjs from 'dayjs';

import type { ProductEvent, SingleProductType } from '../../../data/ProductType';

export const useCreateBasicProductEvents = ({
  product, setCalendarEvents, isEditing,
} : { product       : SingleProductType | null,
  isEditing         : boolean,
  setCalendarEvents : React.Dispatch<React.SetStateAction<ProductEvent[]>>, }) : void => {

  useEffect(() => {
    if (!product || !isEditing) {
      return;
    }
    setCalendarEvents(
      product.events
        ? [
          ...product.events,
          {
            id            : Date.now(),
            status        : dayjs(product.maturityDate).isAfter(dayjs()) ? 'Future' : 'Past',
            eventType     : 'Maturity',
            paymentDate   : dayjs(product.maturityDate).format('YYYY-MM-DD'),
            valuationDate : dayjs(product.maturityDate).format('YYYY-MM-DD'),
          },
          {
            id            : Date.now(),
            status        : dayjs(product.issueDate).isAfter(dayjs()) ? 'Future' : 'Past',
            eventType     : 'Issue',
            paymentDate   : dayjs(product.issueDate).format('YYYY-MM-DD'),
            valuationDate : dayjs(product.issueDate).format('YYYY-MM-DD'),
          },
          {
            id            : Date.now(),
            status        : dayjs(product.initialValuationDate).isAfter(dayjs()) ? 'Future' : 'Past',
            eventType     : 'Initial Fixing',
            paymentDate   : dayjs(product.initialValuationDate).format('YYYY-MM-DD'),
            valuationDate : dayjs(product.initialValuationDate).format('YYYY-MM-DD'),
          },

          // Add today event marker
          {
            id            : Date.now(),
            status        : 'Future',
            eventType     : 'Today',
            paymentDate   : dayjs().format('YYYY-MM-DD'),
            valuationDate : dayjs().format('YYYY-MM-DD'),
          },
        ]
        : []
    );
  }, [product]);
};
