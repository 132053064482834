/* eslint-disable id-denylist */
import type { ReactNode } from 'react';

import { FieldsNameEnum } from './enums/pricing';

import type { PostPriceBody } from './PostPriceBody';
import type { TradeDataType } from './TradesTypes';
import type { CurrencyNameType } from './currency';

export type ProductType = {
  id                : string,
  name              : string,
  transformFunction : (postPriceBody : PostPriceBody)=> PostPriceBody,
  hideFields        : string [],
};

const phoenixAutocall : ProductType = {
  id                : 'phoenixAutocall',
  name              : 'Phoenix Autocall',
  transformFunction : (d) : PostPriceBody => d,
  hideFields        : [],
};

export const classicAutocall : ProductType = {
  id                : 'classicAutocall',
  name              : 'Classic Autocall',
  transformFunction : (postPriceBody) : PostPriceBody => ({
    ...postPriceBody,
    coupon : {
      ...postPriceBody.coupon,
      couponTrigger  : postPriceBody.autocall.autocallTrigger,
      couponStepdown : postPriceBody.autocall.autocallStepdown,
      couponFloor    : postPriceBody.autocall.autocallFloor,
    },
  }),
  hideFields : [FieldsNameEnum.couponTrigger, FieldsNameEnum.couponStepdown, FieldsNameEnum.couponFloor],
};

export const autocallableReverseConvertible : ProductType = {
  id                : 'autocallableReverseConvertible',
  name              : 'Autocallable Reverse Convertible',
  transformFunction : (postPriceBody) : PostPriceBody => ({
    ...postPriceBody,
    coupon : {
      ...postPriceBody.coupon,
      couponTrigger  : 0,
      couponStepdown : 0,
      couponFloor    : 0,
    },
  }),
  hideFields : [FieldsNameEnum.couponTrigger, FieldsNameEnum.couponStepdown, FieldsNameEnum.couponFloor],
};

export const reverseConvertible : ProductType = {
  id                : 'reverseConvertible',
  name              : 'Reverse Convertible',
  transformFunction : (postPriceBody) : PostPriceBody => ({
    ...postPriceBody,
    autocall : {
      ...postPriceBody.autocall,
      nonCall          : (postPriceBody.maturity / postPriceBody.coupon.frequency) - 1,
      autocallTrigger  : 100,
      autocallStepdown : 0,
      autocallFloor    : 0,
    },
    coupon : {
      ...postPriceBody.coupon,
      couponTrigger  : 0,
      couponStepdown : 0,
      couponFloor    : 0,
    },
  }),
  hideFields : [
    FieldsNameEnum.nonCall,
    FieldsNameEnum.autocallTrigger,
    FieldsNameEnum.autocallStepdown,
    FieldsNameEnum.autocallFloor,
    FieldsNameEnum.couponTrigger,
    FieldsNameEnum.couponStepdown,
    FieldsNameEnum.couponFloor,
  ],
};

const digital : ProductType = {

  id                : 'digital',
  name              : 'Digital',
  transformFunction : (postPriceBody) : PostPriceBody => ({
    ...postPriceBody,
    autocall : {
      ...postPriceBody.autocall,
      nonCall         : postPriceBody.maturity / postPriceBody.coupon.frequency,
      autocallTrigger : postPriceBody.coupon.couponTrigger,
    },
  }),
  hideFields : [FieldsNameEnum.autocallTrigger],
};

export const ProductTypeList = [
  phoenixAutocall,
  classicAutocall,
  autocallableReverseConvertible,
  reverseConvertible,
  digital,
];

export const ProductTypeOptionsList = ProductTypeList.map((e) => ({
  value : e.id,
  label : e.name,
}));

export const hideField = (fieldName : string, productId : string) : boolean => ProductTypeList
  .find((p) => p.id === productId)
  ?.hideFields
  .includes(fieldName)
  ?? false;

export const transformPostPriceDataBody = (postPriceBody : PostPriceBody) : PostPriceBody => ProductTypeList
  .find((p) => p.id === postPriceBody.productType)
  ?.transformFunction(postPriceBody)
  ?? postPriceBody;

export type SingleProductType = {
  id                    : number,
  isin                  : string,
  name                  : string,
  productType           : string,
  productSubType        : string,
  issuerId              : number,
  issuerShortName       : string,
  issuerFullName        : string,
  issueDate             : Date,
  maturityDate          : Date,
  nextEventDate         : Date,
  initialValuationDate  : Date,
  currency              : CurrencyNameType,
  totalDuration         : number,
  valorisation          : number,
  denomination          : number,
  status                : string,
  nextEventStatus       : string,
  termSheet             : string,
  finalRedemptionValue  : number,
  cummulativeCouponEarn : number,
  autocall              : Autocall,
  couponBarrier         : CouponBarrier,
  couponFixed ?         : CouponFixed,
  couponVariable ?      : CouponVariable,
  events ?              : ProductEvent[],
  fields ?              : ProductField[],
  trades                : TradeDataType[],
};

export type ProductField = {
  id          : number,
  customKey   : string,
  customValue : string,
};

export type ProductEvent = {
  id                : number,
  status            : string,
  eventType         : string,
  valuationDate     : string | Date,
  paymentDate       : string | Date,
  outcomeValue ?    : string,
  couponTrigger ?   : number,
  autocallTrigger ? : number,
};

type Autocall = {
  id               : number,
  basketType       : string,
  barrierType      : string,
  nonCallPeriod    : number,
  initialBarrier   : number,
  stepdown         : number,
  trigger          : number,
  autocallFloor    : number,
  stepDownOverride : string,
};

type CouponBarrier = {
  id               : number,
  basketType       : string,
  barrierType      : string,
  memoryEffect     : boolean,
  initialBarrier   : number,
  stepDown         : number,
  stepDownOverride : string,
};

type CouponFixed = {
  id            : number,
  couponValue   : number,
  numberPerYear : number,
};

type CouponVariable = {
  id            : number,
  numberPerYear : number,
  cap           : number,
  floor         : number,
  fixedLeg      : number,
  floatingLeg   : string,
};

export type ProductListItem = {
  id              : number,
  isin            : string,
  name            : string,
  productType     : string,
  productSubType  : string,
  issuerShortName : string,
  issuerFullName  : string,
  issueDate       : string,
  maturityDate    : string,
  nextEventDate   : string,
  currency        : CurrencyNameType,
  totalDuration   : number,
  valorisation    : number,
  denomination    : number,
  status          : string,
  nextEventStatus : string,
  trades          : TradeDataType[],
};

export type ProductEventType = {
  key           : string,
  isin          : string,
  name          : string,
  date          : string,
  potentialFlow : number,
  termsheet     : string,
  actualFlow    : number,
  status        : string,
  validate      : ReactNode,
};

export type ProductFormFieldType = {
  isin              : string,
  name              : string,
  issuerId          : number,
  productType       : string,
  productSubType    : string,
  currencyRefId     : number,
  termSheet         : string,
  issueDate         : string,
  maturityLength    : number,
  initialFixingDate : string,
  basketType        : string,

  coupon                   : number,
  frequency                : string,
  couponTrigger            : number,
  couponStepdown           : number,
  couponMemoryEffect       : boolean,
  couponFloor              : number,
  hasCouponFloor           : boolean,
  couponStepDownOverride   : number,
  couponCap                : number,
  targetCoupon             : number,
  firstFloatingPaymentDate : string,

  autocallTrigger          : number,
  autocallStepdown         : number,
  nonCallPeriod            : number,
  autocallStepDownOverride : number,
  hasAutocallFloor         : boolean,
  autocallFloor            : number,

  barrierType      : string,
  barrierLevel     : number,
  strike           : number,
  downsideLeverage : number,

  cap                      : number,
  bonusLevel               : number,
  bonusBarrier             : number,
  upsideLeverage           : number,
  rebate                   : number,
  rebateBarrier            : number,
  rebateBarrierType        : string,
  downsideStrike           : number,
  maturityDownsideLeverage : number,
  upsideStrike             : number,
  capitalGuaranteeLevel    : number,
  participationStrike      : number,
  maturityCap              : number,
  hasMaturityCap           : boolean,
  hasMaturityRebate        : boolean,
  floorValue               : number,

  attachmentPoint        : number,
  detachmentPoint        : number,
  recoveryType           : string,
  callable               : boolean,
  firstCouponPaymentDate : string,
  optionRebate           : number,
  optionRebate2          : number,

  optionType         : string,
  optionPosition     : string,
  optionStrike       : number,
  optionLeverage     : number,
  optionBarrierLevel : number,
  optionBarrierType  : string,
  optionAsBarrier    : boolean,

  optionType2         : string,
  optionPosition2     : string,
  optionStrike2       : number,
  optionLeverage2     : number,
  optionBarrierLevel2 : number,
  optionBarrierType2  : string,
  optionAsBarrier2    : boolean,
};

export type ProductApiSubmissionForm = {
  isin                 : string,
  name                 : string,
  issuerId             : number,
  productType          : string,
  currencyRefId        : number,
  termSheet            : string,
  status               : string,
  totalDuration        : number,
  productSubType       : string,
  maturityDate         : string,
  issueDate            : string,
  initialValuationDate : string,
  underlyings          : UnderlyingEquity[],
  underlyingsRates     : string[],
  autocall ?: {
    nonCallPeriod   : number,
    initialBarrier  : number,
    stepdown        : number,
    barrierList ?: number[],
    autocallFloor ?: number,
  },
  couponBarrier ?: {
    memoryEffect ?: boolean,
    initialBarrier ?: number,
    stepdown               : number,
    barrierList ?: number[],
    couponFloor ?: number,
    targetCoupon ?: number,
    firstFloatingPayment ?: string,
  },
  couponVariable ?: {
    numberPerYear : number,
    cap           : number,
    floor         : number,
    fixedLeg      : number,
    floatingLeg   : string,
  },
  couponFixed ?: {
    couponValue   : number,
    numberPerYear : number,
  },
  redemptions ?: ProductRedemption[],
  basketType       : string,
  strike           : number,
  downsideLeverage : number,
  events           : ProductEvent[],
  fields : {
    customKey   : string,
    customValue : string,
  }[],
};

export type UnderlyingEquity = {
  isin            : string,
  tickerWithPlace : string,
  ticker          : string,
  companyId       : number,
};

export type CompanyItemType = {
  companyId   : number,
  companyName : string,
  isin        : string,
};

export type ProductRedemption = {
  startValue             : number,
  isStartIncluded        : boolean,
  endValue               : number | null,
  isEndIncluded          : boolean,
  redemptionValueAtStart : number,
  redemptionValueAtEnd   : number,
  isActivated            : boolean,
  addOrReplace           : 'Add' | 'Replace' | null,
  barrierType ?          : string,
};
